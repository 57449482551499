import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { fetchWrapper } from '../helpers';

// create slice

const name = 'parametres';
const initialState = createInitialState();
const extraActions = createExtraActions();
const extraReducers = createExtraReducers();
const slice = createSlice({ name, initialState, extraReducers });

// exports

export const parametreActions = { ...slice.actions, ...extraActions };
export const parametresReducer = slice.reducer;

// implementation

function createInitialState() {
    return {
        parametres: {},
        parametresLoad:false,
        error: null
    }
}

function createExtraActions() {

    const baseUrl = `${process.env.REACT_APP_API_URL}/api/parametres/`;

    return {
      getAll: getAll()
    };    

    function getAll() {
        return createAsyncThunk(
            `${name}/getAll`,
            async (user) => fetchWrapper.get(baseUrl,'',user)
        );
    }
}

function createExtraReducers() {
    return {
        ...getAll()
    };

    function getAll() {
        const { pending, fulfilled, rejected } = extraActions.getAll;
        return {
            [pending]: (state, action) => {
              state.error = action.error;
              state.parametresLoad = false;
            },
            [fulfilled]: (state, action) => {
                state.parametres = action.payload;
                state.parametresLoad = true;
            },
            [rejected]: (state, action) => {
              state.error = action.error;
            }
        };
    }
}
