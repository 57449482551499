import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Iconify from '../../../components/iconify';

export default function TableCredits() {
  const { credits, loadCredits } = useSelector(x => x.credits);
  let rows = [];
  if(loadCredits){
    rows = credits.slice(0,5);
  }

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Date</TableCell>
            <TableCell align="left">Pokou</TableCell>
            <TableCell align="left">Description</TableCell>
            <TableCell align="left">Facture</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow
              key={row.id}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {row.datecredit}
              </TableCell>
              <TableCell align="left">{row.credit}</TableCell>
              <TableCell align="left">{row.description}</TableCell>
              <TableCell align="left">{row.facture !== 0 ? <Link to={`/pdf/${row.facture}`} target="_blank"><Iconify icon="teenyicons:pdf-outline" color="#ff4842" /></Link> : ''}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}