import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { Box, Link } from '@mui/material';
import './logo.css';

// ----------------------------------------------------------------------

const Logo = forwardRef(({ disabledLink = false, sx, ...other }, ref) => {

  // OR using local (public folder)
  // -------------------------------------------------------
  // const logo = (
  //   <Box
  //     component="img"
  //     src="/logo/logo_single.svg" => your path
  //     sx={{ width: 40, height: 40, cursor: 'pointer', ...sx }}
  //   />
  // );

  const logo = (
    <Box
      ref={ref}
      component="div"
      sx={{
        width: 220,
        height: 'auto',
        display: 'inline-flex',
        ...sx,
      }}
      {...other}
    >
      <svg version="1.1" id="Calque_1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px" viewBox="0 0 1920 433" >
      <path className="st0" d="M253.3,70.5c25.1,7.2,46.8,20.5,64.1,37.8l29.8-23.2c-22.1-23.3-50.5-41.2-83.5-50.7c-33-9.5-66.6-9.4-97.7-1.5  l12.9,35.5C202.7,63,228.2,63.3,253.3,70.5z"/>
      <path className="st1" d="M68,173c11.9-41.3,40.1-73.5,75.6-91.7l-12.9-35.5c-46.4,22.4-83.5,63.6-98.8,116.8c-3.8,13.1-6,26.3-6.9,39.3  l37.8-1.3C63.6,191.4,65.3,182.2,68,173z"/>
      <path className="st2" d="M188.8,362.3l-10.5,36.4c67.6,12.3,135-13.7,177.3-64.3l-31.3-21.2C291.2,350.8,240.2,370.4,188.8,362.3z"/>
      <path className="st3" d="M64.1,238.2l-37.8,1.3c8.9,65.4,52.1,123.3,115.9,148.8l10.5-36.4C104.8,331.4,72.1,287.7,64.1,238.2z"/>
      <path className="st4" d="M280.1,137.4c-11.1-9.9-24.5-17.6-39.7-22c-15.2-4.4-30.7-5-45.3-2.5c-12.5,2.1-24.5,6.5-35.3,12.8  c-22,12.9-39.3,33.8-46.9,60.2c-1.3,4.4-2.1,8.8-2.8,13.2c-1.9,12.8-1.5,25.5,1.2,37.5c6.6,29.8,26.4,55.8,54.5,69.9  c5.5,2.8,11.4,5.1,17.5,6.9c6.2,1.8,12.4,2.9,18.5,3.5c31.3,3.1,61.9-8.5,83.4-30.2c8.7-8.8,15.8-19.3,21-31.2  c1.8-4.1,3.4-8.2,4.6-12.6c7.6-26.4,4.1-53.3-7.7-75.9C297.4,155.9,289.6,145.8,280.1,137.4z M253.2,265.1  c-11.4,9.3-25.7,14.6-40.7,14.8c-6.2,0.1-12.5-0.7-18.8-2.5c-6.3-1.8-12.1-4.5-17.3-7.9c-12.6-8.1-21.9-20.3-26.6-34.2  c-4-11.8-4.6-24.7-1.3-37.5c0.1-0.5,0.2-1,0.3-1.5c4.1-14.3,12.8-26.1,23.9-34.3c10.2-7.5,22.4-12.1,35.3-12.8  c7.2-0.4,14.5,0.3,21.8,2.4c7.3,2.1,13.9,5.4,19.7,9.6c10.5,7.5,18.5,17.8,23.1,29.7c5,12.9,6.1,27.5,2,41.8  c-0.1,0.5-0.3,0.9-0.5,1.4C270.3,246.6,262.8,257.2,253.2,265.1z"/>
      <g>
        <g>
          <path className="st5" d="M485,274.4c0,1.1,0.7,2.1,1.8,2.5l8.4,4.6c1.1,0.4,1.8,1.4,1.8,2.5v8.4c0,1.1-1.1,1.8-2.1,1.8h-44    c-1.1,0-1.8-0.7-1.8-1.8v-8.4c0-1.1,0.7-2.1,1.8-2.5l8.4-4.6c1.1-0.4,1.8-1.4,1.8-2.5V54.8c0-1.1-0.7-2.1-1.8-2.5l-8.4-4.6    c-1.1-0.4-1.8-1.4-1.8-2.5v-8.4c0-0.7,0.7-2.1,1.8-2.5l14.4-4.6c1.1-0.4,2.5-0.7,3.5-0.7h14.1c1.1,0,2.1,1.1,2.1,2.1V274.4z"/>
          <path className="st5" d="M677.1,281.5c1.1,0.4,1.8,1.4,1.8,2.5v8.4c0,1.1-0.7,1.8-1.8,1.8h-26.4c-1.1,0-2.1-0.7-2.1-1.8l-5.3-20.8    l-0.4-3.5v-1.8c0-0.7-0.4-1.1-1.1-1.1c-0.7,0-1.4,1.1-1.8,1.8c-6.7,10.9-23.6,31.3-56.3,31.3c-29.9,0-60.9-17.2-60.9-52.8    c0-59.8,64-57.7,110.2-57.7h8.1c0.7,0,1.4-0.7,1.4-1.4c-1.4-38-20.1-56-41.5-56c-25,0-36.2,8.1-45,16.5c-1.4,1.4-2.1,2.8-2.5,3.9    l-2.8,9.1c-0.4,1.1-1.4,1.8-2.5,2.1l-7.7,1.4c-1.1,0.4-2.5-0.4-2.8-1.4l-3.5-7.7c-3.2-7-2.8-8.4,1.8-13.4    c14.1-17.2,33.1-27.8,65.1-27.8c45.4,0,65.8,36.2,65.8,76.4v0.4v84.5c0,1.1,0.7,2.1,1.8,2.5L677.1,281.5z M643,208.3    c0-1.1-0.7-1.8-1.8-1.8h-8.4c-41.9,0-84.8-1.1-84.8,37.3c0,16.9,13,35.5,39.8,35.5c34.5,0,55.3-37.3,55.3-68.3V208.3z"/>
          <path className="st5" d="M729.5,170.6c-9.5,0-16.9-7.4-16.9-16.9c0-9.1,7.4-16.5,16.9-16.5c9.1,0,16.5,7.4,16.5,16.5    C746.1,163.2,738.7,170.6,729.5,170.6z M729.5,297.6c-9.5,0-16.9-7.4-16.9-16.9c0-9.1,7.4-16.5,16.9-16.5    c9.1,0,16.5,7.4,16.5,16.5C746.1,290.3,738.7,297.6,729.5,297.6z"/>
          <path className="st5" d="M908,184.7c0-31.3-15.8-53.1-39.1-53.1c-28.2,0-50.3,24.6-50.3,52.4v84.5v23.9c0,1.1-1.1,1.8-2.1,1.8h-32    c-1.1,0-1.8-0.7-1.8-1.8v-8.4c0-1.1,0.7-2.1,1.8-2.5l8.4-4.6c1.1-0.4,1.8-1.4,1.8-2.5V142.1c0-1.1-0.7-2.5-1.8-2.8l-8.4-4.2    c-1.1-0.4-1.8-1.8-1.8-2.8v-8.1c0-1.1,0.7-2.1,1.8-2.5l21.1-4.9c0.7-0.4,2.5-0.4,3.5-0.4h1.8c0.7,0,1.8,0.7,2.1,1.8l4.9,20.1    c0.4,1.1,0.4,1.8,0.7,2.1c0.7,0.7,1.8,0.7,2.8-0.7c11.3-16.9,29.9-26.4,51-26.4c21.8,0,41.5,12.3,52.1,34.1c0.7,1.4,2.5,1.4,3.5,0    c12.3-19.4,28.9-34.1,57.7-34.1c31.3,0,59.1,25,59.1,66.9v94.3c0,1.1,0.7,2.1,1.8,2.5l8.4,4.6c1.1,0.4,1.8,1.4,1.8,2.5v8.4    c0,1.1-0.7,1.8-1.8,1.8h-32c-1.1,0-2.1-0.7-2.1-1.8V184.7c0-31.3-15.8-53.1-38.7-53.1c-28.2,0-50.7,24.6-50.7,52.4v90.4    c0,1.1,1.1,2.1,1.8,2.5l8.8,4.6c0.7,0.4,1.8,1.4,1.8,2.5v8.4c0,1.1-1.1,1.8-2.1,1.8h-44c-1.1,0-1.8-0.7-1.8-1.8v-8.4    c0-1.1,0.7-2.1,1.4-2.5l8.8-4.6c0.7-0.4,1.8-1.4,1.8-2.5V184.7z"/>
          <path className="st5" d="M1234.5,281.5c1.1,0.4,1.8,1.4,1.8,2.5v8.4c0,1.1-0.7,1.8-1.8,1.8h-26.4c-1.1,0-2.1-0.7-2.1-1.8l-5.3-20.8    l-0.4-3.5v-1.8c0-0.7-0.4-1.1-1.1-1.1c-0.7,0-1.4,1.1-1.8,1.8c-6.7,10.9-23.6,31.3-56.3,31.3c-29.9,0-60.9-17.2-60.9-52.8    c0-59.8,64-57.7,110.2-57.7h8.1c0.7,0,1.4-0.7,1.4-1.4c-1.4-38-20.1-56-41.5-56c-25,0-36.2,8.1-45,16.5c-1.4,1.4-2.1,2.8-2.5,3.9    l-2.8,9.1c-0.4,1.1-1.4,1.8-2.5,2.1l-7.7,1.4c-1.1,0.4-2.5-0.4-2.8-1.4l-3.5-7.7c-3.2-7-2.8-8.4,1.8-13.4    c14.1-17.2,33.1-27.8,65.1-27.8c45.4,0,65.8,36.2,65.8,76.4v0.4v84.5c0,1.1,0.7,2.1,1.8,2.5L1234.5,281.5z M1200.4,208.3    c0-1.1-0.7-1.8-1.8-1.8h-8.4c-41.9,0-84.8-1.1-84.8,37.3c0,16.9,13,35.5,39.8,35.5c34.5,0,55.3-37.3,55.3-68.3V208.3z"/>
          <path className="st5" d="M1357.7,116.4c1.1,0,2.1,0.7,2.1,1.8v8.8c0,1.1-1.1,2.1-2.1,2.1l-20.4,5.3c-1.1,0.4-2.8,0.4-3.9,0.4h-26.7    c-0.7,0-1.4,0.7-1.4,1.4v138.3c0,1.1,0.7,2.1,1.8,2.5l8.4,4.6c1.1,0.4,1.8,1.4,1.8,2.5v8.4c0,1.1-0.7,1.8-1.8,1.8h-44    c-1.1,0-2.1-0.7-2.1-1.8v-8.4c0-1.1,0.7-2.1,1.8-2.5l8.4-4.6c1.1-0.4,1.8-1.4,1.8-2.5V136.1c0-0.7-0.7-1.4-1.4-1.4h-22.5    c-1.1,0-1.8-0.7-1.8-1.8v-8.8c0-1.1,0.7-2.1,1.8-2.5l20.8-4.9c0.4,0,1.1-0.4,1.8-0.4s1.4-0.7,1.4-1.4V92.8c0-1.1-0.7-2.5-1.8-2.8    l-8.4-4.2c-1.1-0.4-1.8-1.8-1.8-2.8v-8.1c0-1.1,0.7-2.1,1.8-2.5l14.4-4.9c1.1-0.4,2.5-0.4,3.5-0.4h14.4c1.1,0,1.8,0.7,1.8,1.8V109    v5.3v0.7c0,0.7,0.7,1.4,1.4,1.4H1357.7z"/>
          <path className="st5" d="M1410.1,138.6c0.4,0.7,0.4,1.4,0.7,1.8c0.7,0.7,2.1,0.7,2.8-0.4c9.9-15.8,29.6-26.7,50-26.7    c2.8,0,8.8,0.4,14.4,1.8c0.7,0,1.4,0.7,1.1,1.4l-4.9,20.1c-0.4,1.1-1.1,1.4-2.1,1.1c-5.3-2.1-11.3-3.2-17.6-3.2    c-22.9,0-43.6,20.8-43.6,46.1v94c0,1.1,0.7,2.1,1.8,2.5l8.4,4.6c1.1,0.4,1.8,1.4,1.8,2.5v8.4c0,1.1-1.1,1.8-2.1,1.8h-44    c-1.1,0-1.8-0.7-1.8-1.8v-8.4c0-1.1,0.7-2.1,1.8-2.5l8.4-4.6c1.1-0.4,1.8-1.4,1.8-2.5V142.1c0-1.1-0.7-2.1-1.8-2.8l-8.4-4.2    c-1.1-0.4-1.8-1.8-1.8-2.8v-8.1c0-1.1,0.7-2.1,1.8-2.1l21.1-5.3c0.7-0.4,2.5-0.4,3.5-0.4h1.8c0.7,0,1.8,0.7,2.1,1.8L1410.1,138.6z    "/>
          <path className="st5" d="M1540.3,281.5c1.1,0.4,1.8,1.4,1.8,2.5v8.4c0,1.1-1.1,1.8-2.1,1.8h-44c-1.1,0-1.8-0.7-1.8-1.8v-8.4    c0-1.1,0.7-2.1,1.8-2.5l8.4-4.6c1.1-0.4,1.8-1.4,1.8-2.5V142.1c0-1.1-0.7-2.5-1.8-2.8l-8.4-4.2c-1.1-0.4-1.8-1.8-1.8-2.8v-8.1    c0-1.1,0.7-2.1,1.8-2.5l14.4-4.9c1.1-0.4,2.5-0.4,3.5-0.4h14.1c1.1,0,2.1,0.7,2.1,1.8v156.3c0,1.1,0.7,2.1,1.8,2.5L1540.3,281.5z     M1518.2,90.4c-9.1,0-16.9-7.4-16.9-16.5c0-9.5,7.7-16.9,16.9-16.9c9.2,0,16.9,7.4,16.9,16.9C1535.1,83,1527.3,90.4,1518.2,90.4z"/>
          <path className="st5" d="M1713.5,263.9c0,2.1-0.7,3.9-2.5,5.6c-11.6,11.3-32.7,28.9-61.9,28.9c-47.9,0-79.5-36.2-79.5-92.6    c0-57,32.4-92.6,79.9-92.6c18.7,0,27.4,3.2,42.2,12.7c0.7,0.4,1.1,0.4,1.8-0.4l7-8.4c0.7-0.7,2.1-1.1,3.2-0.7l8.1,1.8    c1.1,0.4,1.8,1.4,1.8,2.5v33.8c0,1.1-0.4,2.1-1.8,2.5l-7.4,1.8c-2.5,0.7-3.5,0.7-5.6-1.1c-1.4-1.1-2.5-2.5-4.2-4.6    c-12-14.1-25-21.1-45-21.1c-36.2,0-57.4,28.9-57.4,73.9c0,44.3,20.8,73.9,57.4,73.9c12.7,0,27.8-5.3,41.2-17.2    c2.5-2.1,11.6-21.1,11.6-21.1c0.7-1.4,1.8-1.4,2.8-1.4h6.7c1.1,0,1.8,0.7,1.8,1.8V263.9z"/>
          <path className="st5" d="M1884.5,265.3c0,1.8-0.7,3.5-2.5,5.3c-11.6,11.3-33.4,27.8-62.3,27.8c-50.3,0-80.6-38.4-80.6-93.3    c0-59.5,36.2-91.9,80.9-91.9c40.8,0,71.1,37.3,73.9,85.2c0,1.8,0,2.1-1.8,2.5l-20.4,5.3c-1.1,0.4-2.8,0.4-3.9,0.4h-104.9    c-1.1,0-1.8,1.1-1.8,1.8v0.7c1.8,39.8,17.9,70.4,58.1,70.4c12.7,0,29.2-3.5,42.2-15.8c1.8-1.4,11.6-21.1,11.6-21.1    c0.7-0.7,1.8-1.4,2.8-1.4h6.3c1.1,0,2.1,0.7,2.1,1.8V265.3z M1866.6,187.8c1.4,0,2.1-1.1,1.8-1.8c-4.6-32.4-21.8-54.2-49.6-54.2    c-28.9,0-52.1,21.8-55.6,54.2c0,1.1,1.1,1.8,2.1,1.8H1866.6z"/>
        </g>
      </g>
      <g>
        <path className="st5" d="M463.6,403.6c0,0.2,0,0.3-0.1,0.4c-0.1,0.1-0.2,0.2-0.4,0.3c-0.2,0.1-0.4,0.1-0.7,0.2   c-0.3,0.1-0.7,0.1-1.1,0.1c-0.4,0-0.8,0-1.1-0.1c-0.3-0.1-0.5-0.1-0.7-0.2c-0.2-0.1-0.3-0.2-0.4-0.3c-0.1-0.1-0.1-0.3-0.1-0.4   v-52.3c0-0.2,0-0.3,0.1-0.4c0.1-0.1,0.2-0.2,0.4-0.3c0.2-0.1,0.4-0.2,0.7-0.2c0.3,0,0.7-0.1,1.1-0.1c0.5,0,0.8,0,1.1,0.1   c0.3,0,0.5,0.1,0.7,0.2c0.2,0.1,0.3,0.2,0.4,0.3c0.1,0.1,0.1,0.3,0.1,0.4V403.6z"/>
        <path className="st5" d="M521,384.2c0,0.9-0.2,1.6-0.7,1.9c-0.5,0.3-1,0.5-1.4,0.5h-23.9c0,2.2,0.2,4.1,0.6,5.9   c0.4,1.8,1.1,3.3,2.1,4.5c1,1.3,2.3,2.2,3.8,2.9c1.6,0.7,3.5,1,5.8,1c1.6,0,3.1-0.1,4.4-0.4c1.3-0.3,2.4-0.6,3.4-0.9   c0.9-0.3,1.7-0.7,2.3-0.9c0.6-0.3,1-0.4,1.3-0.4c0.2,0,0.3,0,0.4,0.1c0.1,0.1,0.2,0.2,0.3,0.4c0.1,0.2,0.1,0.4,0.2,0.6   c0,0.3,0.1,0.6,0.1,1c0,0.2,0,0.4,0,0.5c0,0.2-0.1,0.3-0.1,0.5c0,0.1-0.1,0.3-0.2,0.4c-0.1,0.1-0.2,0.3-0.3,0.4   c-0.1,0.1-0.5,0.4-1.2,0.7c-0.7,0.3-1.6,0.6-2.6,1c-1.1,0.3-2.3,0.6-3.8,0.9c-1.4,0.3-3,0.4-4.6,0.4c-2.7,0-5.1-0.4-7.2-1.2   c-2.1-0.8-3.9-2-5.3-3.6c-1.4-1.6-2.5-3.6-3.2-5.9c-0.7-2.4-1.1-5.1-1.1-8.2c0-3,0.4-5.6,1.1-8c0.8-2.4,1.9-4.3,3.3-6   c1.4-1.6,3.1-2.9,5.1-3.8c2-0.9,4.2-1.3,6.7-1.3c2.6,0,4.9,0.4,6.7,1.3c1.9,0.9,3.4,2.1,4.6,3.5c1.2,1.5,2.1,3.2,2.6,5.2   c0.6,2,0.8,4,0.8,6.2V384.2z M516.2,382.9c0.1-3.7-0.8-6.6-2.6-8.7c-1.8-2.1-4.4-3.1-7.8-3.1c-1.8,0-3.3,0.3-4.6,1   c-1.3,0.7-2.5,1.5-3.4,2.6c-0.9,1.1-1.6,2.3-2.1,3.8c-0.5,1.4-0.8,2.9-0.9,4.5H516.2z"/>
        <path className="st5" d="M605.8,398.4c0,0.3,0,0.6,0,0.8c0,0.2-0.1,0.5-0.1,0.6c-0.1,0.2-0.1,0.3-0.2,0.5c-0.1,0.1-0.3,0.4-0.6,0.7   c-0.3,0.3-0.9,0.8-1.7,1.3c-0.8,0.5-1.7,1-2.7,1.4c-1,0.4-2,0.7-3.2,0.9c-1.1,0.2-2.3,0.4-3.5,0.4c-2.4,0-4.6-0.4-6.5-1.2   c-1.9-0.8-3.4-2-4.7-3.6c-1.3-1.6-2.2-3.5-2.9-5.9c-0.7-2.3-1-5-1-8c0-3.4,0.4-6.3,1.2-8.8c0.8-2.4,1.9-4.4,3.4-6   c1.4-1.6,3.1-2.7,5-3.4c1.9-0.7,3.9-1.1,6.1-1.1c1.2,0,2.3,0.1,3.3,0.3c1.1,0.2,2,0.5,2.9,0.9c0.9,0.4,1.7,0.8,2.4,1.2   c0.7,0.5,1.2,0.9,1.5,1.2c0.3,0.3,0.5,0.6,0.7,0.7c0.1,0.2,0.2,0.4,0.3,0.6c0.1,0.2,0.1,0.4,0.1,0.7c0,0.2,0,0.5,0,0.9   c0,0.7-0.1,1.3-0.3,1.6c-0.2,0.3-0.5,0.4-0.7,0.4c-0.4,0-0.8-0.2-1.3-0.7c-0.5-0.5-1.2-1-2-1.5c-0.8-0.6-1.8-1.1-3-1.5   c-1.2-0.5-2.6-0.7-4.2-0.7c-3.4,0-6,1.3-7.8,3.9c-1.8,2.6-2.7,6.3-2.7,11.2c0,2.5,0.2,4.6,0.7,6.5c0.5,1.8,1.2,3.4,2.1,4.6   c0.9,1.2,2,2.1,3.4,2.8c1.3,0.6,2.8,0.9,4.5,0.9c1.6,0,2.9-0.2,4.1-0.7c1.2-0.5,2.2-1,3.1-1.6c0.9-0.6,1.6-1.1,2.2-1.6   c0.6-0.5,1-0.7,1.3-0.7c0.2,0,0.3,0,0.4,0.1c0.1,0.1,0.2,0.2,0.3,0.4c0.1,0.2,0.1,0.4,0.2,0.7C605.8,397.7,605.8,398,605.8,398.4z"/>
        <path className="st5" d="M661.3,385.7c0,2.8-0.4,5.4-1.1,7.7c-0.7,2.4-1.8,4.4-3.2,6.1c-1.4,1.7-3.2,3.1-5.4,4.1   c-2.1,1-4.6,1.5-7.5,1.5c-2.7,0-5.1-0.4-7.2-1.3c-2.1-0.9-3.8-2.1-5.2-3.7c-1.4-1.6-2.4-3.6-3.1-5.9c-0.7-2.3-1-4.9-1-7.8   c0-2.8,0.4-5.4,1.1-7.7c0.7-2.4,1.8-4.4,3.2-6.1c1.4-1.7,3.2-3.1,5.4-4c2.1-1,4.7-1.4,7.5-1.4c2.7,0,5.1,0.4,7.2,1.3   c2.1,0.9,3.8,2.1,5.1,3.7c1.4,1.6,2.4,3.6,3.1,5.9C660.9,380.2,661.3,382.8,661.3,385.7z M656.4,386.1c0-2-0.2-3.9-0.6-5.7   c-0.4-1.8-1-3.4-1.9-4.8c-0.9-1.4-2.1-2.5-3.6-3.3c-1.5-0.8-3.4-1.2-5.7-1.2c-2.1,0-3.9,0.4-5.4,1.1c-1.5,0.7-2.8,1.8-3.7,3.1   c-1,1.3-1.7,2.9-2.2,4.7c-0.5,1.8-0.7,3.8-0.7,5.9c0,2,0.2,3.9,0.6,5.8c0.4,1.8,1,3.4,1.9,4.8c0.9,1.4,2.1,2.5,3.6,3.3   c1.5,0.8,3.4,1.2,5.7,1.2c2.1,0,3.9-0.4,5.4-1.1c1.5-0.7,2.8-1.8,3.7-3.1c1-1.3,1.7-2.9,2.2-4.7   C656.1,390.2,656.4,388.2,656.4,386.1z"/>
        <path className="st5" d="M733.7,368.4c0,0.1,0,0.3-0.1,0.4c0,0.2-0.1,0.4-0.1,0.6l-10.9,34.2c-0.1,0.2-0.1,0.4-0.3,0.5   c-0.1,0.1-0.3,0.2-0.6,0.3c-0.2,0.1-0.5,0.1-0.9,0.2c-0.4,0-0.8,0-1.3,0c-0.5,0-1,0-1.4-0.1c-0.4,0-0.7-0.1-0.9-0.2   c-0.2-0.1-0.4-0.2-0.6-0.3c-0.1-0.1-0.2-0.3-0.3-0.5l-8.1-27.7l-0.1-0.3l-0.1,0.3l-7.6,27.7c-0.1,0.2-0.2,0.4-0.3,0.5   c-0.2,0.1-0.4,0.2-0.6,0.3c-0.3,0.1-0.6,0.1-1,0.2c-0.4,0-0.8,0-1.3,0c-0.5,0-1,0-1.3-0.1c-0.4,0-0.7-0.1-0.9-0.2   c-0.2-0.1-0.4-0.2-0.6-0.3c-0.1-0.1-0.2-0.3-0.3-0.5l-10.8-34.2c-0.1-0.2-0.1-0.4-0.2-0.5c0-0.2-0.1-0.3-0.1-0.5   c0-0.2,0-0.3,0.1-0.5c0.1-0.1,0.2-0.2,0.4-0.3c0.2-0.1,0.4-0.1,0.7-0.1c0.3,0,0.6,0,1,0c0.5,0,1,0,1.3,0.1c0.3,0,0.6,0.1,0.8,0.2   c0.2,0.1,0.3,0.2,0.4,0.3c0.1,0.1,0.1,0.3,0.2,0.5l9.3,30.6l0.1,0.3l0.1-0.3l8.4-30.6c0.1-0.2,0.1-0.3,0.2-0.5   c0.1-0.1,0.2-0.2,0.4-0.3c0.2-0.1,0.4-0.1,0.7-0.2c0.3,0,0.7-0.1,1.1-0.1c0.4,0,0.8,0,1.1,0.1c0.3,0,0.5,0.1,0.7,0.2   c0.2,0.1,0.3,0.2,0.4,0.3c0.1,0.1,0.1,0.3,0.2,0.5l9.1,30.6l0.1,0.3l0-0.3l9.2-30.6c0.1-0.2,0.1-0.3,0.2-0.5   c0.1-0.1,0.2-0.2,0.4-0.3c0.2-0.1,0.4-0.1,0.8-0.2c0.3,0,0.7-0.1,1.3-0.1c0.4,0,0.7,0,1,0c0.3,0,0.5,0.1,0.6,0.2   c0.2,0.1,0.3,0.2,0.3,0.3C733.6,368.1,733.7,368.3,733.7,368.4z"/>
        <path className="st5" d="M788.7,385.7c0,2.8-0.4,5.4-1.1,7.7c-0.7,2.4-1.8,4.4-3.2,6.1c-1.4,1.7-3.2,3.1-5.4,4.1   c-2.1,1-4.6,1.5-7.5,1.5c-2.7,0-5.1-0.4-7.2-1.3c-2.1-0.9-3.8-2.1-5.2-3.7c-1.4-1.6-2.4-3.6-3.1-5.9c-0.7-2.3-1-4.9-1-7.8   c0-2.8,0.4-5.4,1.1-7.7c0.7-2.4,1.8-4.4,3.2-6.1c1.4-1.7,3.2-3.1,5.4-4c2.1-1,4.7-1.4,7.5-1.4c2.7,0,5.1,0.4,7.2,1.3   c2.1,0.9,3.8,2.1,5.1,3.7c1.4,1.6,2.4,3.6,3.1,5.9C788.4,380.2,788.7,382.8,788.7,385.7z M783.8,386.1c0-2-0.2-3.9-0.6-5.7   c-0.4-1.8-1-3.4-1.9-4.8c-0.9-1.4-2.1-2.5-3.6-3.3c-1.5-0.8-3.4-1.2-5.7-1.2c-2.1,0-3.9,0.4-5.4,1.1c-1.5,0.7-2.8,1.8-3.7,3.1   c-1,1.3-1.7,2.9-2.2,4.7c-0.5,1.8-0.7,3.8-0.7,5.9c0,2,0.2,3.9,0.6,5.8c0.4,1.8,1,3.4,1.9,4.8c0.9,1.4,2.1,2.5,3.6,3.3   c1.5,0.8,3.4,1.2,5.7,1.2c2.1,0,3.9-0.4,5.4-1.1c1.5-0.7,2.8-1.8,3.7-3.1c1-1.3,1.7-2.9,2.2-4.7   C783.6,390.2,783.8,388.2,783.8,386.1z"/>
        <path className="st5" d="M834.4,370.3c0,0.4,0,0.7,0,1c0,0.3-0.1,0.5-0.1,0.7c-0.1,0.2-0.1,0.3-0.2,0.4c-0.1,0.1-0.2,0.1-0.4,0.1   c-0.2,0-0.5-0.1-0.8-0.2c-0.3-0.1-0.7-0.2-1.1-0.4c-0.4-0.1-0.9-0.3-1.4-0.4c-0.5-0.1-1-0.2-1.6-0.2c-0.7,0-1.4,0.1-2.1,0.4   c-0.7,0.3-1.4,0.8-2.1,1.4c-0.7,0.7-1.5,1.5-2.3,2.6c-0.8,1.1-1.7,2.4-2.7,4v23.7c0,0.2,0,0.3-0.1,0.4c-0.1,0.1-0.2,0.2-0.4,0.3   c-0.2,0.1-0.4,0.1-0.7,0.2c-0.3,0.1-0.7,0.1-1.1,0.1c-0.4,0-0.8,0-1.1-0.1c-0.3-0.1-0.5-0.1-0.7-0.2c-0.2-0.1-0.3-0.2-0.4-0.3   c-0.1-0.1-0.1-0.3-0.1-0.4v-35.1c0-0.2,0-0.3,0.1-0.4c0.1-0.1,0.2-0.2,0.4-0.3c0.2-0.1,0.4-0.2,0.7-0.2c0.3,0,0.6-0.1,1.1-0.1   c0.4,0,0.8,0,1.1,0.1c0.3,0,0.5,0.1,0.7,0.2c0.2,0.1,0.3,0.2,0.3,0.3c0.1,0.1,0.1,0.3,0.1,0.4v5.5c1-1.5,1.9-2.7,2.8-3.6   c0.9-0.9,1.7-1.6,2.4-2.1c0.8-0.5,1.5-0.8,2.3-1c0.7-0.2,1.5-0.3,2.3-0.3c0.3,0,0.7,0,1.2,0.1c0.4,0,0.9,0.1,1.4,0.2   c0.5,0.1,1,0.2,1.4,0.4c0.4,0.1,0.7,0.3,0.8,0.4c0.2,0.1,0.3,0.2,0.3,0.3c0.1,0.1,0.1,0.2,0.1,0.3s0.1,0.3,0.1,0.6   C834.4,369.6,834.4,369.9,834.4,370.3z"/>
        <path className="st5" d="M884.9,403.5c0,0.2,0,0.3-0.1,0.5c-0.1,0.1-0.2,0.2-0.4,0.3c-0.2,0.1-0.4,0.2-0.7,0.2   c-0.3,0.1-0.7,0.1-1.2,0.1c-0.5,0-0.9,0-1.3-0.1c-0.3,0-0.6-0.1-0.9-0.2c-0.2-0.1-0.5-0.2-0.6-0.3c-0.2-0.1-0.3-0.3-0.5-0.5   l-16.4-19.9v19.9c0,0.2,0,0.3-0.1,0.4c-0.1,0.1-0.2,0.2-0.4,0.3c-0.2,0.1-0.4,0.1-0.7,0.2c-0.3,0.1-0.7,0.1-1.1,0.1   c-0.4,0-0.8,0-1.1-0.1c-0.3-0.1-0.5-0.1-0.7-0.2c-0.2-0.1-0.3-0.2-0.4-0.3c-0.1-0.1-0.1-0.3-0.1-0.4v-52.3c0-0.2,0-0.3,0.1-0.4   c0.1-0.1,0.2-0.2,0.4-0.3c0.2-0.1,0.4-0.2,0.7-0.2c0.3,0,0.7-0.1,1.1-0.1c0.5,0,0.8,0,1.1,0.1c0.3,0,0.5,0.1,0.7,0.2   c0.2,0.1,0.3,0.2,0.4,0.3c0.1,0.1,0.1,0.3,0.1,0.4v32.3l14.8-14.9c0.2-0.2,0.4-0.4,0.6-0.6c0.2-0.1,0.5-0.3,0.7-0.3   c0.3-0.1,0.6-0.2,0.9-0.2c0.3,0,0.7-0.1,1.2-0.1c0.4,0,0.8,0,1.1,0c0.3,0,0.6,0.1,0.8,0.2c0.2,0.1,0.4,0.2,0.5,0.3   c0.1,0.1,0.2,0.3,0.2,0.4c0,0.3-0.1,0.5-0.2,0.8c-0.2,0.2-0.4,0.6-0.8,0.9l-14,13.3l15.6,18.6c0.3,0.4,0.6,0.7,0.7,0.9   C884.9,403.1,884.9,403.3,884.9,403.5z"/>
        <path className="st5" d="M914.4,356.7c0,1.2-0.2,2.1-0.7,2.5c-0.4,0.4-1.2,0.7-2.4,0.7c-1.1,0-1.9-0.2-2.3-0.6   c-0.4-0.4-0.6-1.3-0.6-2.5c0-1.2,0.2-2.1,0.7-2.5c0.4-0.4,1.2-0.7,2.4-0.7c1.1,0,1.9,0.2,2.3,0.6   C914.2,354.6,914.4,355.4,914.4,356.7z M913.7,403.6c0,0.2,0,0.3-0.1,0.4c-0.1,0.1-0.2,0.2-0.4,0.3c-0.2,0.1-0.4,0.1-0.7,0.2   c-0.3,0.1-0.7,0.1-1.1,0.1c-0.4,0-0.8,0-1.1-0.1c-0.3-0.1-0.5-0.1-0.7-0.2c-0.2-0.1-0.3-0.2-0.4-0.3c-0.1-0.1-0.1-0.3-0.1-0.4   v-35.1c0-0.2,0-0.3,0.1-0.4c0.1-0.1,0.2-0.2,0.4-0.3c0.2-0.1,0.4-0.2,0.7-0.2c0.3,0,0.7-0.1,1.1-0.1c0.5,0,0.8,0,1.1,0.1   c0.3,0,0.5,0.1,0.7,0.2c0.2,0.1,0.3,0.2,0.4,0.3c0.1,0.1,0.1,0.3,0.1,0.4V403.6z"/>
        <path className="st5" d="M970.9,403.6c0,0.2,0,0.3-0.1,0.4c-0.1,0.1-0.2,0.2-0.4,0.3c-0.2,0.1-0.4,0.1-0.7,0.2   c-0.3,0.1-0.7,0.1-1.1,0.1c-0.5,0-0.8,0-1.1-0.1c-0.3-0.1-0.5-0.1-0.7-0.2c-0.2-0.1-0.3-0.2-0.4-0.3c-0.1-0.1-0.1-0.3-0.1-0.4   v-20.8c0-2.2-0.2-3.9-0.5-5.3c-0.3-1.4-0.8-2.5-1.5-3.5c-0.7-1-1.5-1.7-2.6-2.2c-1-0.5-2.3-0.8-3.7-0.8c-1.8,0-3.6,0.6-5.4,1.9   c-1.8,1.3-3.7,3.1-5.6,5.6v25.2c0,0.2,0,0.3-0.1,0.4c-0.1,0.1-0.2,0.2-0.4,0.3c-0.2,0.1-0.4,0.1-0.7,0.2c-0.3,0.1-0.7,0.1-1.1,0.1   c-0.4,0-0.8,0-1.1-0.1c-0.3-0.1-0.5-0.1-0.7-0.2c-0.2-0.1-0.3-0.2-0.4-0.3c-0.1-0.1-0.1-0.3-0.1-0.4v-35.1c0-0.2,0-0.3,0.1-0.4   c0.1-0.1,0.2-0.2,0.4-0.3c0.2-0.1,0.4-0.2,0.7-0.2c0.3,0,0.6-0.1,1.1-0.1c0.4,0,0.8,0,1.1,0.1c0.3,0,0.5,0.1,0.7,0.2   c0.2,0.1,0.3,0.2,0.3,0.3c0.1,0.1,0.1,0.3,0.1,0.4v5c2-2.2,4-3.9,5.9-4.9s3.9-1.5,5.8-1.5c2.3,0,4.2,0.4,5.8,1.2   c1.6,0.8,2.8,1.8,3.8,3.1s1.7,2.9,2.1,4.6c0.4,1.8,0.6,3.9,0.6,6.4V403.6z"/>
        <path className="st5" d="M1027.7,369.7c0,0.7-0.1,1.2-0.3,1.5c-0.2,0.3-0.5,0.4-0.9,0.4h-5.5c1.1,1.1,1.8,2.3,2.3,3.6   c0.4,1.3,0.6,2.7,0.6,4.1c0,2-0.3,3.8-0.9,5.3c-0.6,1.6-1.5,2.9-2.7,3.9c-1.2,1.1-2.5,1.9-4.1,2.5c-1.6,0.6-3.4,0.9-5.3,0.9   c-1.6,0-3.1-0.2-4.5-0.7c-1.4-0.4-2.5-1-3.3-1.6c-0.5,0.5-0.9,1.1-1.3,1.8c-0.3,0.7-0.5,1.4-0.5,2.3c0,1,0.5,1.8,1.4,2.5   c0.9,0.7,2.2,1,3.7,1.1l9.5,0.4c1.6,0.1,3.1,0.3,4.5,0.7c1.4,0.4,2.6,1,3.7,1.7c1.1,0.8,1.9,1.7,2.5,2.8c0.6,1.1,0.9,2.4,0.9,3.9   c0,1.6-0.3,3-0.9,4.5c-0.6,1.4-1.6,2.7-2.9,3.7c-1.3,1.1-3,1.9-5.1,2.6c-2.1,0.6-4.5,0.9-7.4,0.9c-2.7,0-5.1-0.2-7-0.7   c-1.9-0.5-3.5-1.1-4.7-2c-1.2-0.8-2.1-1.8-2.7-3c-0.6-1.1-0.8-2.4-0.8-3.7c0-0.9,0.1-1.8,0.3-2.6c0.2-0.8,0.5-1.6,1-2.3   c0.4-0.7,1-1.4,1.6-2.1c0.6-0.7,1.4-1.4,2.2-2.1c-1.3-0.7-2.3-1.5-2.9-2.4c-0.6-1-0.9-2-0.9-3.1c0-1.6,0.3-3,0.9-4.2   c0.6-1.2,1.4-2.3,2.2-3.3c-0.8-1-1.3-2.1-1.7-3.2c-0.4-1.2-0.6-2.6-0.6-4.2c0-2,0.3-3.7,1-5.3c0.6-1.6,1.5-2.9,2.7-4   c1.2-1.1,2.5-1.9,4.1-2.5c1.6-0.6,3.4-0.9,5.3-0.9c1,0,2,0.1,2.8,0.2c0.9,0.1,1.7,0.3,2.5,0.4h10.3c0.4,0,0.8,0.2,1,0.5   C1027.6,368.5,1027.7,369,1027.7,369.7z M1022.8,407.1c0-1.7-0.7-3-2.1-3.9c-1.4-0.9-3.2-1.4-5.6-1.5l-10.2-0.3   c-0.9,0.7-1.7,1.4-2.3,2.1c-0.6,0.6-1.1,1.3-1.4,1.9c-0.3,0.6-0.6,1.2-0.7,1.8c-0.1,0.6-0.2,1.2-0.2,1.8c0,1.9,1,3.4,2.9,4.3   c1.9,1,4.6,1.5,8,1.5c2.2,0,4-0.2,5.4-0.6c1.5-0.4,2.6-1,3.5-1.7c0.9-0.7,1.6-1.5,1.9-2.4C1022.6,409,1022.8,408.1,1022.8,407.1z    M1019.1,379.4c0-2.7-0.7-4.7-2.2-6.2c-1.5-1.5-3.5-2.2-6.2-2.2c-1.4,0-2.6,0.2-3.6,0.7c-1,0.5-1.9,1.1-2.6,1.9   c-0.7,0.8-1.2,1.7-1.5,2.8c-0.3,1.1-0.5,2.2-0.5,3.4c0,2.6,0.7,4.6,2.2,6.1c1.5,1.5,3.5,2.2,6.1,2.2c1.4,0,2.6-0.2,3.7-0.7   s1.9-1.1,2.6-1.9c0.7-0.8,1.2-1.7,1.5-2.8C1018.9,381.7,1019.1,380.6,1019.1,379.4z"/>
        <path className="st5" d="M1117.2,385.5c0,3-0.3,5.7-1,8.1c-0.7,2.4-1.6,4.4-2.9,6.1c-1.3,1.7-2.9,3-4.7,3.9c-1.9,0.9-4,1.4-6.5,1.4   c-1,0-2-0.1-2.9-0.3s-1.8-0.6-2.7-1c-0.9-0.5-1.7-1-2.6-1.7c-0.9-0.7-1.8-1.5-2.7-2.5v18.1c0,0.2,0,0.3-0.1,0.4   c-0.1,0.1-0.2,0.2-0.4,0.3c-0.2,0.1-0.4,0.2-0.7,0.2c-0.3,0-0.7,0.1-1.1,0.1c-0.4,0-0.8,0-1.1-0.1c-0.3,0-0.5-0.1-0.7-0.2   c-0.2-0.1-0.3-0.2-0.4-0.3c-0.1-0.1-0.1-0.3-0.1-0.4v-49c0-0.2,0-0.3,0.1-0.5c0.1-0.1,0.2-0.2,0.3-0.3c0.2-0.1,0.4-0.1,0.7-0.2   c0.3,0,0.6-0.1,1-0.1c0.4,0,0.7,0,1,0.1c0.3,0,0.5,0.1,0.7,0.2c0.2,0.1,0.3,0.2,0.4,0.3c0.1,0.1,0.1,0.3,0.1,0.5v5.1   c1.1-1.1,2.1-2.1,3.1-2.9c1-0.8,2-1.5,2.9-2c1-0.5,2-0.9,3-1.2c1-0.3,2.1-0.4,3.3-0.4c2.5,0,4.7,0.5,6.5,1.5c1.8,1,3.2,2.3,4.4,4   c1.1,1.7,1.9,3.6,2.4,5.9C1116.9,380.6,1117.2,383,1117.2,385.5z M1112.3,386.3c0-2-0.2-3.9-0.5-5.7c-0.3-1.8-0.8-3.4-1.6-4.9   c-0.7-1.4-1.7-2.6-2.9-3.4s-2.8-1.3-4.6-1.3c-0.9,0-1.8,0.1-2.7,0.4c-0.9,0.3-1.8,0.7-2.7,1.3c-0.9,0.6-1.9,1.4-2.9,2.3   c-1,0.9-2.1,2.1-3.2,3.5v15.3c1.9,2.4,3.8,4.2,5.6,5.4c1.8,1.3,3.6,1.9,5.6,1.9c1.8,0,3.3-0.4,4.6-1.3c1.3-0.9,2.3-2,3.1-3.4   c0.8-1.4,1.4-3,1.8-4.8C1112.1,389.8,1112.3,388,1112.3,386.3z"/>
        <path className="st5" d="M1168.2,403.6c0,0.3-0.1,0.5-0.3,0.6c-0.2,0.1-0.4,0.2-0.7,0.3c-0.3,0.1-0.6,0.1-1.1,0.1c-0.4,0-0.8,0-1.1-0.1   c-0.3-0.1-0.5-0.2-0.7-0.3c-0.2-0.1-0.3-0.3-0.3-0.6v-3.9c-1.6,1.7-3.3,3-5.2,4c-1.9,0.9-3.9,1.4-5.9,1.4c-1.8,0-3.5-0.2-4.9-0.7   c-1.5-0.5-2.7-1.2-3.7-2.1c-1-0.9-1.8-2-2.3-3.2c-0.5-1.3-0.8-2.7-0.8-4.3c0-1.9,0.4-3.6,1.2-5.1c0.8-1.4,1.9-2.6,3.4-3.6   c1.5-1,3.2-1.7,5.3-2.2c2.1-0.5,4.4-0.7,6.9-0.7h5.6v-3c0-1.5-0.2-2.8-0.5-4c-0.3-1.1-0.8-2.1-1.5-2.9c-0.7-0.8-1.6-1.4-2.7-1.7   c-1.1-0.4-2.5-0.6-4.1-0.6c-1.6,0-2.9,0.2-4.2,0.6c-1.2,0.4-2.3,0.8-3.3,1.3c-0.9,0.5-1.7,0.9-2.3,1.3c-0.6,0.4-1,0.6-1.3,0.6   c-0.2,0-0.3,0-0.4-0.1c-0.1-0.1-0.2-0.1-0.3-0.3c-0.1-0.1-0.2-0.3-0.2-0.5c0-0.2-0.1-0.5-0.1-0.8c0-0.5,0-1,0.1-1.3   c0.1-0.3,0.3-0.6,0.6-1c0.3-0.3,0.9-0.7,1.7-1.1c0.8-0.4,1.8-0.8,2.8-1.2c1.1-0.4,2.2-0.7,3.5-0.9c1.2-0.2,2.5-0.3,3.7-0.3   c2.4,0,4.4,0.3,6.1,0.9c1.7,0.6,3,1.4,4,2.5c1,1.1,1.8,2.4,2.2,4c0.4,1.6,0.7,3.4,0.7,5.4V403.6z M1163.6,387h-6.4   c-1.9,0-3.5,0.2-4.9,0.5c-1.4,0.3-2.6,0.8-3.5,1.5c-0.9,0.6-1.6,1.4-2.1,2.3c-0.5,0.9-0.7,1.9-0.7,3.1c0,2,0.6,3.6,1.9,4.8   c1.3,1.2,3.1,1.8,5.4,1.8c1.8,0,3.5-0.5,5.1-1.4c1.6-0.9,3.3-2.4,5.1-4.4V387z"/>
        <path className="st5" d="M1216.4,370.3c0,0.4,0,0.7,0,1c0,0.3-0.1,0.5-0.1,0.7c-0.1,0.2-0.1,0.3-0.2,0.4c-0.1,0.1-0.2,0.1-0.4,0.1   c-0.2,0-0.5-0.1-0.8-0.2c-0.3-0.1-0.7-0.2-1.1-0.4c-0.4-0.1-0.9-0.3-1.4-0.4c-0.5-0.1-1-0.2-1.6-0.2c-0.7,0-1.4,0.1-2.1,0.4   c-0.7,0.3-1.4,0.8-2.1,1.4c-0.7,0.7-1.5,1.5-2.3,2.6c-0.8,1.1-1.7,2.4-2.7,4v23.7c0,0.2,0,0.3-0.1,0.4c-0.1,0.1-0.2,0.2-0.4,0.3   c-0.2,0.1-0.4,0.1-0.7,0.2c-0.3,0.1-0.7,0.1-1.1,0.1c-0.4,0-0.8,0-1.1-0.1c-0.3-0.1-0.5-0.1-0.7-0.2c-0.2-0.1-0.3-0.2-0.4-0.3   c-0.1-0.1-0.1-0.3-0.1-0.4v-35.1c0-0.2,0-0.3,0.1-0.4c0.1-0.1,0.2-0.2,0.4-0.3c0.2-0.1,0.4-0.2,0.7-0.2c0.3,0,0.6-0.1,1.1-0.1   c0.4,0,0.8,0,1.1,0.1c0.3,0,0.5,0.1,0.7,0.2c0.2,0.1,0.3,0.2,0.3,0.3c0.1,0.1,0.1,0.3,0.1,0.4v5.5c1-1.5,1.9-2.7,2.8-3.6   c0.9-0.9,1.7-1.6,2.4-2.1c0.8-0.5,1.5-0.8,2.3-1c0.7-0.2,1.5-0.3,2.3-0.3c0.3,0,0.7,0,1.2,0.1c0.4,0,0.9,0.1,1.4,0.2   c0.5,0.1,1,0.2,1.4,0.4c0.4,0.1,0.7,0.3,0.8,0.4c0.2,0.1,0.3,0.2,0.3,0.3c0.1,0.1,0.1,0.2,0.1,0.3c0,0.1,0.1,0.3,0.1,0.6   C1216.4,369.6,1216.4,369.9,1216.4,370.3z"/>
        <path className="st5" d="M1278.6,403.6c0,0.2,0,0.3-0.1,0.4c-0.1,0.1-0.2,0.2-0.4,0.3c-0.2,0.1-0.4,0.1-0.7,0.2   c-0.3,0.1-0.7,0.1-1.1,0.1c-0.4,0-0.8,0-1.1-0.1c-0.3-0.1-0.5-0.1-0.7-0.2c-0.2-0.1-0.3-0.2-0.4-0.3c-0.1-0.1-0.1-0.3-0.1-0.4   v-52.3c0-0.2,0-0.3,0.1-0.4c0.1-0.1,0.2-0.2,0.4-0.3c0.2-0.1,0.4-0.2,0.7-0.2c0.3,0,0.7-0.1,1.1-0.1c0.5,0,0.8,0,1.1,0.1   c0.3,0,0.5,0.1,0.7,0.2c0.2,0.1,0.3,0.2,0.4,0.3c0.1,0.1,0.1,0.3,0.1,0.4V403.6z"/>
        <path className="st5" d="M1336,384.2c0,0.9-0.2,1.6-0.7,1.9c-0.5,0.3-1,0.5-1.4,0.5h-23.9c0,2.2,0.2,4.1,0.6,5.9   c0.4,1.8,1.1,3.3,2.1,4.5c1,1.3,2.3,2.2,3.8,2.9c1.6,0.7,3.5,1,5.8,1c1.6,0,3.1-0.1,4.4-0.4c1.3-0.3,2.4-0.6,3.4-0.9   c0.9-0.3,1.7-0.7,2.3-0.9c0.6-0.3,1-0.4,1.3-0.4c0.2,0,0.3,0,0.4,0.1c0.1,0.1,0.2,0.2,0.3,0.4c0.1,0.2,0.1,0.4,0.2,0.6   c0,0.3,0.1,0.6,0.1,1c0,0.2,0,0.4,0,0.5c0,0.2-0.1,0.3-0.1,0.5c0,0.1-0.1,0.3-0.2,0.4c-0.1,0.1-0.2,0.3-0.3,0.4   c-0.1,0.1-0.5,0.4-1.2,0.7c-0.7,0.3-1.5,0.6-2.6,1c-1.1,0.3-2.3,0.6-3.8,0.9c-1.4,0.3-3,0.4-4.6,0.4c-2.7,0-5.1-0.4-7.2-1.2   s-3.9-2-5.3-3.6c-1.4-1.6-2.5-3.6-3.2-5.9c-0.7-2.4-1.1-5.1-1.1-8.2c0-3,0.4-5.6,1.1-8c0.8-2.4,1.9-4.3,3.3-6s3.1-2.9,5.1-3.8   c2-0.9,4.2-1.3,6.7-1.3c2.6,0,4.9,0.4,6.7,1.3c1.9,0.9,3.4,2.1,4.6,3.5c1.2,1.5,2.1,3.2,2.6,5.2c0.6,2,0.8,4,0.8,6.2V384.2z    M1331.2,382.9c0.1-3.7-0.8-6.6-2.6-8.7c-1.8-2.1-4.4-3.1-7.8-3.1c-1.8,0-3.3,0.3-4.6,1c-1.3,0.7-2.5,1.5-3.4,2.6   c-0.9,1.1-1.6,2.3-2.1,3.8c-0.5,1.4-0.8,2.9-0.9,4.5H1331.2z"/>
        <path className="st5" d="M1383.1,394.3c0,1.7-0.3,3.2-0.9,4.5c-0.6,1.3-1.5,2.5-2.7,3.4c-1.2,0.9-2.5,1.6-4.1,2.1   c-1.6,0.5-3.4,0.7-5.3,0.7c-1.2,0-2.4-0.1-3.5-0.3c-1.1-0.2-2.1-0.4-2.9-0.7c-0.9-0.3-1.6-0.6-2.2-0.9c-0.6-0.3-1.1-0.6-1.3-0.9   c-0.3-0.3-0.5-0.6-0.6-1c-0.1-0.4-0.2-0.9-0.2-1.6c0-0.3,0-0.6,0.1-0.9c0.1-0.3,0.1-0.5,0.2-0.7c0.1-0.2,0.2-0.3,0.3-0.4   c0.1-0.1,0.3-0.1,0.4-0.1c0.3,0,0.7,0.2,1.2,0.5c0.5,0.4,1.2,0.8,2,1.2c0.8,0.4,1.8,0.8,2.9,1.2c1.1,0.4,2.4,0.5,3.9,0.5   c1.2,0,2.3-0.1,3.3-0.4c1-0.3,1.8-0.6,2.6-1.2c0.7-0.5,1.3-1.2,1.7-2c0.4-0.8,0.6-1.8,0.6-2.8c0-1.1-0.3-2-0.8-2.8   c-0.5-0.7-1.2-1.4-2-2c-0.8-0.6-1.8-1-2.9-1.5c-1.1-0.4-2.2-0.9-3.3-1.3c-1.1-0.5-2.2-0.9-3.3-1.5c-1.1-0.5-2-1.2-2.9-2   c-0.8-0.8-1.5-1.7-2-2.8c-0.5-1.1-0.8-2.4-0.8-3.9c0-1.2,0.2-2.4,0.7-3.6c0.5-1.2,1.2-2.2,2.2-3.2c1-0.9,2.2-1.7,3.7-2.2   c1.5-0.6,3.3-0.9,5.4-0.9c0.9,0,1.8,0.1,2.6,0.2c0.9,0.1,1.7,0.3,2.4,0.6c0.7,0.2,1.3,0.5,1.8,0.8c0.5,0.3,0.9,0.5,1.2,0.7   c0.3,0.2,0.5,0.4,0.5,0.5c0.1,0.1,0.2,0.3,0.2,0.4c0,0.2,0.1,0.3,0.1,0.6c0,0.2,0,0.5,0,0.8c0,0.3,0,0.6-0.1,0.8   c0,0.2-0.1,0.5-0.2,0.6c-0.1,0.2-0.2,0.3-0.3,0.4c-0.1,0.1-0.3,0.1-0.4,0.1c-0.2,0-0.5-0.1-0.9-0.4c-0.4-0.3-1-0.6-1.6-0.9   c-0.7-0.3-1.5-0.6-2.4-0.9c-0.9-0.3-2-0.4-3.2-0.4c-1.2,0-2.3,0.1-3.2,0.4c-0.9,0.3-1.7,0.7-2.3,1.2c-0.6,0.5-1,1.1-1.3,1.8   c-0.3,0.7-0.4,1.5-0.4,2.3c0,1.1,0.3,2.1,0.8,2.9c0.5,0.8,1.2,1.4,2.1,2c0.9,0.6,1.8,1.1,2.9,1.5c1.1,0.4,2.2,0.9,3.3,1.3   c1.1,0.4,2.2,0.9,3.3,1.5c1.1,0.5,2.1,1.2,2.9,1.9c0.9,0.8,1.5,1.6,2.1,2.7C1382.9,391.5,1383.1,392.8,1383.1,394.3z"/>
        <path className="st5" d="M1467.4,398.4c0,0.3,0,0.6,0,0.8c0,0.2-0.1,0.5-0.1,0.6c-0.1,0.2-0.1,0.3-0.2,0.5c-0.1,0.1-0.3,0.4-0.6,0.7   c-0.3,0.3-0.9,0.8-1.7,1.3c-0.8,0.5-1.7,1-2.7,1.4c-1,0.4-2,0.7-3.2,0.9c-1.1,0.2-2.3,0.4-3.5,0.4c-2.4,0-4.6-0.4-6.5-1.2   c-1.9-0.8-3.4-2-4.7-3.6c-1.3-1.6-2.2-3.5-2.9-5.9c-0.7-2.3-1-5-1-8c0-3.4,0.4-6.3,1.2-8.8c0.8-2.4,1.9-4.4,3.4-6s3.1-2.7,5-3.4   s3.9-1.1,6.1-1.1c1.2,0,2.3,0.1,3.3,0.3c1.1,0.2,2,0.5,2.9,0.9c0.9,0.4,1.7,0.8,2.4,1.2c0.7,0.5,1.2,0.9,1.5,1.2   c0.3,0.3,0.5,0.6,0.7,0.7c0.1,0.2,0.2,0.4,0.3,0.6c0.1,0.2,0.1,0.4,0.1,0.7c0,0.2,0,0.5,0,0.9c0,0.7-0.1,1.3-0.3,1.6   c-0.2,0.3-0.5,0.4-0.7,0.4c-0.4,0-0.8-0.2-1.3-0.7c-0.5-0.5-1.2-1-2-1.5c-0.8-0.6-1.8-1.1-3-1.5c-1.2-0.5-2.6-0.7-4.2-0.7   c-3.4,0-6,1.3-7.8,3.9c-1.8,2.6-2.7,6.3-2.7,11.2c0,2.5,0.2,4.6,0.7,6.5c0.5,1.8,1.2,3.4,2.1,4.6c0.9,1.2,2,2.1,3.4,2.8   c1.3,0.6,2.8,0.9,4.5,0.9c1.5,0,2.9-0.2,4.1-0.7c1.2-0.5,2.2-1,3.1-1.6c0.9-0.6,1.6-1.1,2.2-1.6c0.6-0.5,1-0.7,1.3-0.7   c0.2,0,0.3,0,0.4,0.1c0.1,0.1,0.2,0.2,0.3,0.4c0.1,0.2,0.1,0.4,0.2,0.7C1467.4,397.7,1467.4,398,1467.4,398.4z"/>
        <path className="st5" d="M1522.8,385.7c0,2.8-0.4,5.4-1.1,7.7c-0.7,2.4-1.8,4.4-3.2,6.1c-1.4,1.7-3.2,3.1-5.4,4.1   c-2.1,1-4.6,1.5-7.5,1.5c-2.7,0-5.1-0.4-7.2-1.3c-2.1-0.9-3.8-2.1-5.2-3.7c-1.4-1.6-2.4-3.6-3.1-5.9c-0.7-2.3-1-4.9-1-7.8   c0-2.8,0.4-5.4,1.1-7.7c0.7-2.4,1.8-4.4,3.2-6.1c1.4-1.7,3.2-3.1,5.4-4c2.1-1,4.7-1.4,7.5-1.4c2.7,0,5.1,0.4,7.2,1.3   c2.1,0.9,3.8,2.1,5.1,3.7c1.4,1.6,2.4,3.6,3.1,5.9C1522.5,380.2,1522.8,382.8,1522.8,385.7z M1517.9,386.1c0-2-0.2-3.9-0.6-5.7   c-0.4-1.8-1-3.4-1.9-4.8c-0.9-1.4-2.1-2.5-3.6-3.3c-1.5-0.8-3.4-1.2-5.7-1.2c-2.1,0-3.9,0.4-5.4,1.1c-1.5,0.7-2.8,1.8-3.7,3.1   c-1,1.3-1.7,2.9-2.2,4.7c-0.5,1.8-0.7,3.8-0.7,5.9c0,2,0.2,3.9,0.6,5.8c0.4,1.8,1,3.4,1.9,4.8c0.9,1.4,2.1,2.5,3.6,3.3   c1.5,0.8,3.4,1.2,5.7,1.2c2.1,0,3.9-0.4,5.4-1.1c1.5-0.7,2.8-1.8,3.7-3.1c1-1.3,1.7-2.9,2.2-4.7   C1517.7,390.2,1517.9,388.2,1517.9,386.1z"/>
        <path className="st5" d="M1595.2,368.4c0,0.1,0,0.3-0.1,0.4c0,0.2-0.1,0.4-0.1,0.6l-10.9,34.2c-0.1,0.2-0.1,0.4-0.3,0.5   c-0.1,0.1-0.3,0.2-0.6,0.3c-0.2,0.1-0.5,0.1-0.9,0.2c-0.4,0-0.8,0-1.3,0c-0.5,0-1,0-1.4-0.1c-0.4,0-0.7-0.1-0.9-0.2   c-0.2-0.1-0.4-0.2-0.6-0.3c-0.1-0.1-0.2-0.3-0.3-0.5l-8.1-27.7l-0.1-0.3l-0.1,0.3l-7.6,27.7c-0.1,0.2-0.2,0.4-0.3,0.5   c-0.2,0.1-0.4,0.2-0.6,0.3c-0.3,0.1-0.6,0.1-1,0.2c-0.4,0-0.8,0-1.3,0c-0.5,0-1,0-1.3-0.1c-0.4,0-0.7-0.1-0.9-0.2   c-0.2-0.1-0.4-0.2-0.6-0.3c-0.1-0.1-0.2-0.3-0.3-0.5l-10.8-34.2c-0.1-0.2-0.1-0.4-0.2-0.5c0-0.2-0.1-0.3-0.1-0.5   c0-0.2,0-0.3,0.1-0.5c0.1-0.1,0.2-0.2,0.4-0.3c0.2-0.1,0.4-0.1,0.7-0.1c0.3,0,0.6,0,1,0c0.5,0,1,0,1.3,0.1c0.3,0,0.6,0.1,0.8,0.2   c0.2,0.1,0.3,0.2,0.4,0.3c0.1,0.1,0.1,0.3,0.2,0.5l9.3,30.6l0.1,0.3l0.1-0.3l8.4-30.6c0.1-0.2,0.1-0.3,0.2-0.5   c0.1-0.1,0.2-0.2,0.4-0.3c0.2-0.1,0.4-0.1,0.7-0.2c0.3,0,0.7-0.1,1.1-0.1c0.4,0,0.8,0,1.1,0.1c0.3,0,0.5,0.1,0.7,0.2   c0.2,0.1,0.3,0.2,0.4,0.3c0.1,0.1,0.1,0.3,0.2,0.5l9.1,30.6l0.1,0.3l0-0.3l9.2-30.6c0.1-0.2,0.1-0.3,0.2-0.5   c0.1-0.1,0.2-0.2,0.4-0.3c0.2-0.1,0.4-0.1,0.8-0.2c0.3,0,0.7-0.1,1.3-0.1c0.4,0,0.7,0,1,0c0.3,0,0.5,0.1,0.6,0.2   c0.2,0.1,0.3,0.2,0.3,0.3C1595.2,368.1,1595.2,368.3,1595.2,368.4z"/>
        <path className="st5" d="M1650.3,385.7c0,2.8-0.4,5.4-1.1,7.7c-0.7,2.4-1.8,4.4-3.2,6.1c-1.4,1.7-3.2,3.1-5.4,4.1   c-2.1,1-4.6,1.5-7.5,1.5c-2.7,0-5.1-0.4-7.2-1.3c-2.1-0.9-3.8-2.1-5.2-3.7c-1.4-1.6-2.4-3.6-3.1-5.9c-0.7-2.3-1-4.9-1-7.8   c0-2.8,0.4-5.4,1.1-7.7c0.7-2.4,1.8-4.4,3.2-6.1c1.4-1.7,3.2-3.1,5.4-4c2.1-1,4.7-1.4,7.5-1.4c2.7,0,5.1,0.4,7.2,1.3   c2.1,0.9,3.8,2.1,5.1,3.7c1.4,1.6,2.4,3.6,3.1,5.9C1649.9,380.2,1650.3,382.8,1650.3,385.7z M1645.4,386.1c0-2-0.2-3.9-0.6-5.7   c-0.4-1.8-1-3.4-1.9-4.8c-0.9-1.4-2.1-2.5-3.6-3.3c-1.5-0.8-3.4-1.2-5.7-1.2c-2.1,0-3.9,0.4-5.4,1.1c-1.5,0.7-2.8,1.8-3.7,3.1   c-1,1.3-1.7,2.9-2.2,4.7c-0.5,1.8-0.7,3.8-0.7,5.9c0,2,0.2,3.9,0.6,5.8c0.4,1.8,1,3.4,1.9,4.8c0.9,1.4,2.1,2.5,3.6,3.3   c1.5,0.8,3.4,1.2,5.7,1.2c2.1,0,3.9-0.4,5.4-1.1c1.5-0.7,2.8-1.8,3.7-3.1c1-1.3,1.7-2.9,2.2-4.7   C1645.2,390.2,1645.4,388.2,1645.4,386.1z"/>
        <path className="st5" d="M1696,370.3c0,0.4,0,0.7,0,1c0,0.3-0.1,0.5-0.1,0.7c-0.1,0.2-0.1,0.3-0.2,0.4c-0.1,0.1-0.2,0.1-0.4,0.1   c-0.2,0-0.5-0.1-0.8-0.2c-0.3-0.1-0.7-0.2-1.1-0.4c-0.4-0.1-0.9-0.3-1.4-0.4c-0.5-0.1-1-0.2-1.6-0.2c-0.7,0-1.4,0.1-2.1,0.4   c-0.7,0.3-1.4,0.8-2.1,1.4c-0.7,0.7-1.5,1.5-2.3,2.6c-0.8,1.1-1.7,2.4-2.7,4v23.7c0,0.2,0,0.3-0.1,0.4c-0.1,0.1-0.2,0.2-0.4,0.3   c-0.2,0.1-0.4,0.1-0.7,0.2c-0.3,0.1-0.7,0.1-1.1,0.1c-0.4,0-0.8,0-1.1-0.1c-0.3-0.1-0.5-0.1-0.7-0.2c-0.2-0.1-0.3-0.2-0.4-0.3   c-0.1-0.1-0.1-0.3-0.1-0.4v-35.1c0-0.2,0-0.3,0.1-0.4c0.1-0.1,0.2-0.2,0.4-0.3c0.2-0.1,0.4-0.2,0.7-0.2c0.3,0,0.6-0.1,1.1-0.1   c0.4,0,0.8,0,1.1,0.1c0.3,0,0.5,0.1,0.7,0.2c0.2,0.1,0.3,0.2,0.3,0.3c0.1,0.1,0.1,0.3,0.1,0.4v5.5c1-1.5,1.9-2.7,2.8-3.6   c0.9-0.9,1.7-1.6,2.4-2.1c0.8-0.5,1.5-0.8,2.3-1c0.7-0.2,1.5-0.3,2.3-0.3c0.3,0,0.7,0,1.2,0.1c0.4,0,0.9,0.1,1.4,0.2   c0.5,0.1,1,0.2,1.4,0.4c0.4,0.1,0.7,0.3,0.8,0.4c0.2,0.1,0.3,0.2,0.3,0.3c0.1,0.1,0.1,0.2,0.1,0.3c0,0.1,0.1,0.3,0.1,0.6   C1696,369.6,1696,369.9,1696,370.3z"/>
        <path className="st5" d="M1746.5,403.5c0,0.2,0,0.3-0.1,0.5c-0.1,0.1-0.2,0.2-0.4,0.3c-0.2,0.1-0.4,0.2-0.7,0.2   c-0.3,0.1-0.7,0.1-1.2,0.1c-0.5,0-0.9,0-1.3-0.1c-0.3,0-0.6-0.1-0.9-0.2c-0.2-0.1-0.5-0.2-0.6-0.3c-0.2-0.1-0.3-0.3-0.5-0.5   l-16.4-19.9v19.9c0,0.2,0,0.3-0.1,0.4c-0.1,0.1-0.2,0.2-0.4,0.3c-0.2,0.1-0.4,0.1-0.7,0.2c-0.3,0.1-0.7,0.1-1.1,0.1   c-0.4,0-0.8,0-1.1-0.1c-0.3-0.1-0.5-0.1-0.7-0.2c-0.2-0.1-0.3-0.2-0.4-0.3c-0.1-0.1-0.1-0.3-0.1-0.4v-52.3c0-0.2,0-0.3,0.1-0.4   c0.1-0.1,0.2-0.2,0.4-0.3c0.2-0.1,0.4-0.2,0.7-0.2c0.3,0,0.7-0.1,1.1-0.1c0.5,0,0.8,0,1.1,0.1c0.3,0,0.5,0.1,0.7,0.2   c0.2,0.1,0.3,0.2,0.4,0.3c0.1,0.1,0.1,0.3,0.1,0.4v32.3l14.8-14.9c0.2-0.2,0.4-0.4,0.6-0.6c0.2-0.1,0.5-0.3,0.7-0.3   c0.3-0.1,0.6-0.2,0.9-0.2c0.3,0,0.7-0.1,1.2-0.1c0.4,0,0.8,0,1.1,0c0.3,0,0.6,0.1,0.8,0.2c0.2,0.1,0.4,0.2,0.5,0.3   c0.1,0.1,0.2,0.3,0.2,0.4c0,0.3-0.1,0.5-0.2,0.8c-0.2,0.2-0.4,0.6-0.8,0.9l-14,13.3l15.6,18.6c0.3,0.4,0.6,0.7,0.7,0.9   C1746.4,403.1,1746.5,403.3,1746.5,403.5z"/>
        <path className="st5" d="M1796.5,384.2c0,0.9-0.2,1.6-0.7,1.9c-0.5,0.3-1,0.5-1.4,0.5h-23.9c0,2.2,0.2,4.1,0.6,5.9   c0.4,1.8,1.1,3.3,2.1,4.5c1,1.3,2.3,2.2,3.8,2.9c1.6,0.7,3.5,1,5.8,1c1.6,0,3.1-0.1,4.4-0.4c1.3-0.3,2.4-0.6,3.4-0.9   c0.9-0.3,1.7-0.7,2.3-0.9c0.6-0.3,1-0.4,1.3-0.4c0.2,0,0.3,0,0.4,0.1c0.1,0.1,0.2,0.2,0.3,0.4c0.1,0.2,0.1,0.4,0.2,0.6   c0,0.3,0.1,0.6,0.1,1c0,0.2,0,0.4,0,0.5c0,0.2-0.1,0.3-0.1,0.5c0,0.1-0.1,0.3-0.2,0.4c-0.1,0.1-0.2,0.3-0.3,0.4   c-0.1,0.1-0.5,0.4-1.2,0.7c-0.7,0.3-1.6,0.6-2.6,1c-1.1,0.3-2.3,0.6-3.8,0.9c-1.4,0.3-3,0.4-4.6,0.4c-2.7,0-5.1-0.4-7.2-1.2   c-2.1-0.8-3.9-2-5.3-3.6c-1.4-1.6-2.5-3.6-3.2-5.9c-0.7-2.4-1.1-5.1-1.1-8.2c0-3,0.4-5.6,1.1-8c0.8-2.4,1.9-4.3,3.3-6   s3.1-2.9,5.1-3.8c2-0.9,4.2-1.3,6.7-1.3c2.6,0,4.9,0.4,6.7,1.3c1.9,0.9,3.4,2.1,4.6,3.5c1.2,1.5,2.1,3.2,2.6,5.2   c0.6,2,0.8,4,0.8,6.2V384.2z M1791.8,382.9c0.1-3.7-0.8-6.6-2.6-8.7c-1.8-2.1-4.4-3.1-7.8-3.1c-1.8,0-3.3,0.3-4.6,1   c-1.3,0.7-2.5,1.5-3.4,2.6c-0.9,1.1-1.6,2.3-2.1,3.8c-0.5,1.4-0.8,2.9-0.9,4.5H1791.8z"/>
        <path className="st5" d="M1842.5,370.3c0,0.4,0,0.7,0,1c0,0.3-0.1,0.5-0.1,0.7c-0.1,0.2-0.1,0.3-0.2,0.4c-0.1,0.1-0.2,0.1-0.4,0.1   c-0.2,0-0.5-0.1-0.8-0.2c-0.3-0.1-0.7-0.2-1.1-0.4c-0.4-0.1-0.9-0.3-1.4-0.4c-0.5-0.1-1-0.2-1.6-0.2c-0.7,0-1.4,0.1-2.1,0.4   c-0.7,0.3-1.4,0.8-2.1,1.4c-0.7,0.7-1.5,1.5-2.3,2.6c-0.8,1.1-1.7,2.4-2.7,4v23.7c0,0.2,0,0.3-0.1,0.4c-0.1,0.1-0.2,0.2-0.4,0.3   c-0.2,0.1-0.4,0.1-0.7,0.2c-0.3,0.1-0.7,0.1-1.1,0.1c-0.4,0-0.8,0-1.1-0.1c-0.3-0.1-0.5-0.1-0.7-0.2c-0.2-0.1-0.3-0.2-0.4-0.3   c-0.1-0.1-0.1-0.3-0.1-0.4v-35.1c0-0.2,0-0.3,0.1-0.4c0.1-0.1,0.2-0.2,0.4-0.3c0.2-0.1,0.4-0.2,0.7-0.2c0.3,0,0.6-0.1,1.1-0.1   c0.4,0,0.8,0,1.1,0.1c0.3,0,0.5,0.1,0.7,0.2c0.2,0.1,0.3,0.2,0.3,0.3c0.1,0.1,0.1,0.3,0.1,0.4v5.5c1-1.5,1.9-2.7,2.8-3.6   c0.9-0.9,1.7-1.6,2.4-2.1s1.5-0.8,2.3-1c0.7-0.2,1.5-0.3,2.3-0.3c0.3,0,0.7,0,1.2,0.1c0.4,0,0.9,0.1,1.4,0.2c0.5,0.1,1,0.2,1.4,0.4   c0.4,0.1,0.7,0.3,0.8,0.4c0.2,0.1,0.3,0.2,0.3,0.3c0.1,0.1,0.1,0.2,0.1,0.3c0,0.1,0.1,0.3,0.1,0.6   C1842.5,369.6,1842.5,369.9,1842.5,370.3z"/>
        <path className="st5" d="M1885.4,394.3c0,1.7-0.3,3.2-0.9,4.5c-0.6,1.3-1.5,2.5-2.7,3.4c-1.2,0.9-2.5,1.6-4.1,2.1   c-1.6,0.5-3.4,0.7-5.3,0.7c-1.2,0-2.4-0.1-3.5-0.3c-1.1-0.2-2.1-0.4-2.9-0.7c-0.9-0.3-1.6-0.6-2.2-0.9c-0.6-0.3-1.1-0.6-1.3-0.9   c-0.3-0.3-0.5-0.6-0.6-1c-0.1-0.4-0.2-0.9-0.2-1.6c0-0.3,0-0.6,0.1-0.9c0.1-0.3,0.1-0.5,0.2-0.7c0.1-0.2,0.2-0.3,0.3-0.4   c0.1-0.1,0.3-0.1,0.4-0.1c0.3,0,0.7,0.2,1.2,0.5c0.5,0.4,1.2,0.8,2,1.2c0.8,0.4,1.8,0.8,2.9,1.2c1.1,0.4,2.4,0.5,3.9,0.5   c1.2,0,2.3-0.1,3.3-0.4c1-0.3,1.8-0.6,2.6-1.2c0.7-0.5,1.3-1.2,1.7-2c0.4-0.8,0.6-1.8,0.6-2.8c0-1.1-0.3-2-0.8-2.8   c-0.5-0.7-1.2-1.4-2-2c-0.8-0.6-1.8-1-2.9-1.5c-1.1-0.4-2.2-0.9-3.3-1.3c-1.1-0.5-2.2-0.9-3.3-1.5c-1.1-0.5-2-1.2-2.9-2   c-0.8-0.8-1.5-1.7-2-2.8c-0.5-1.1-0.8-2.4-0.8-3.9c0-1.2,0.2-2.4,0.7-3.6c0.5-1.2,1.2-2.2,2.2-3.2c1-0.9,2.2-1.7,3.7-2.2   c1.5-0.6,3.3-0.9,5.4-0.9c0.9,0,1.8,0.1,2.6,0.2c0.9,0.1,1.7,0.3,2.4,0.6c0.7,0.2,1.3,0.5,1.8,0.8c0.5,0.3,0.9,0.5,1.2,0.7   c0.3,0.2,0.5,0.4,0.5,0.5c0.1,0.1,0.2,0.3,0.2,0.4c0,0.2,0.1,0.3,0.1,0.6c0,0.2,0,0.5,0,0.8c0,0.3,0,0.6-0.1,0.8   c0,0.2-0.1,0.5-0.2,0.6c-0.1,0.2-0.2,0.3-0.3,0.4c-0.1,0.1-0.3,0.1-0.4,0.1c-0.2,0-0.5-0.1-0.9-0.4c-0.4-0.3-1-0.6-1.6-0.9   c-0.7-0.3-1.5-0.6-2.4-0.9c-0.9-0.3-2-0.4-3.2-0.4c-1.2,0-2.3,0.1-3.2,0.4c-0.9,0.3-1.7,0.7-2.3,1.2c-0.6,0.5-1,1.1-1.3,1.8   c-0.3,0.7-0.4,1.5-0.4,2.3c0,1.1,0.3,2.1,0.8,2.9c0.5,0.8,1.2,1.4,2.1,2c0.9,0.6,1.8,1.1,2.9,1.5c1.1,0.4,2.2,0.9,3.3,1.3   c1.1,0.4,2.2,0.9,3.3,1.5c1.1,0.5,2.1,1.2,2.9,1.9c0.9,0.8,1.5,1.6,2.1,2.7C1885.1,391.5,1885.4,392.8,1885.4,394.3z"/>
      </g>
      </svg>
    </Box>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return (
    <Link to="/" component={RouterLink} sx={{ display: 'contents' }}>
      {logo}
    </Link>
  );
});

Logo.propTypes = {
  sx: PropTypes.object,
  disabledLink: PropTypes.bool,
};

export default Logo;
