import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
} from '@mui/material';
// components
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Scrollbar from '../components/scrollbar';
// sections
import TableListHead from '../sections/@dashboard/table/TableListHead';
import TableListToolbar from '../sections/@dashboard/table/TableListToolbar';
import { adhesionActions } from '../store';
import Iconify from '../components/iconify';
import { fDate } from '../utils/formatTime';

// mock
// import adhesions from '../_mock/user';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'datefacture', label: 'Date', alignRight: false },
  { id: 'description', label: 'Description', alignRight: false },
  { id: 'numero', label: 'Numéro', alignRight: false },
  { id: 'montant', label: 'Montant', alignRight: false },
  { id: 'id', label: 'PDF', alignRight: false },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.description.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function AdhesionsPage() {
  const { user } = useSelector(x => x.auth);
  const dispatch = useDispatch();
  useEffect(() => {
    if(user)
    initAdhesions(user);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  function initAdhesions(user){
    dispatch(adhesionActions.getAll(user.idu));
  }

  const { adhesions, adhesiondLoad } = useSelector(x => x.adhesions);

  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('desc');

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('id');

  const [filterName, setFilterName] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleRequestSort = (event, property) => {
    if(property === 'datefacture' || property === 'datecredit'){
      property = 'id'
    }
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = adhesions.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  let emptyRows;
  let filteredUsers;
  let isNotFound;

  if(adhesiondLoad){
    emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - adhesions.length) : 0;
    filteredUsers = applySortFilter(adhesions, getComparator(order, orderBy), filterName);
    isNotFound = !filteredUsers.length && !!filterName;
  }

  return (
    <>
      <Helmet>
        <title> Mes adhesions | La Matrice </title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom color="grey.600">
            Mes adhesions
          </Typography>
        </Stack>

        {adhesiondLoad && <Card>
          <TableListToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <TableListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={adhesions.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                    const { id, datefacture, numero, descf, descc, montant, idfacture, datecredit } = row;
                    const selectedUser = selected.indexOf(id) !== -1;

                    return (
                      <TableRow hover key={id} tabIndex={-1} role="checkbox" selected={selectedUser}>

                        <TableCell align="left">{fDate(datefacture,'dd/MM/yyyy') || fDate(datecredit,'dd/MM/yyyy')}</TableCell>

                        <TableCell align="left">{descf || descc}</TableCell>

                        <TableCell align="left">{numero || 'Achat Pokou'}</TableCell>

                        <TableCell align="left">{`${montant} €`}</TableCell>

                        <TableCell align="left">{idfacture && <Link to={`/pdf/${idfacture}`} target="_blank"><Iconify icon="teenyicons:pdf-outline" color="#ff4842" /></Link>}</TableCell>

                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
                {isNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <Typography variant="h6" paragraph>
                            Pas de résultats
                          </Typography>

                          <Typography variant="body2">
                            Pas de résultats pour &nbsp;
                            <strong>&quot;{filterName}&quot;</strong>.
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[10, 20, 50]}
            component="div"
            count={adhesions.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage="Lignes par page : "
          />
        </Card>}
      </Container>
    </>
  );
}
