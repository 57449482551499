import { Grid } from "@mui/material";
import { Container } from "@mui/system";
import { Helmet } from "react-helmet-async";
import { useTheme } from '@mui/material/styles';
import { useSelector } from "react-redux";
import { AppCurrentVisits } from "../sections/@dashboard/app";
import { repartition } from "../utils/formatChart";

function ChartsPage() {
  const theme = useTheme();
  const {  credits, loadCredits } = useSelector(x => x.credits);
  let chartData = '';
  let creditsSpent = '';
  if(loadCredits && credits.length > 0){
    chartData = repartition(credits);
    creditsSpent = credits.filter(item => item.type === 2 || item.type === 6 || item.type === 8)
  }
  return (
    <>
      <Helmet>
        <title> Statistiques | La:Matrice </title>
      </Helmet>

      <Container>
        <Grid item xs={12} md={12} lg={12} marginTop={{ xs: 0, md: 3, lg: 7 }} >
            {loadCredits && creditsSpent.length > 0 && <AppCurrentVisits
                title="Répartition des dépenses"
                chartData={chartData}
                chartColors={[
                  theme.palette.primary.main,
                  theme.palette.info.main,
                  theme.palette.success.main,
                  theme.palette.secondary.main,
                  theme.palette.error.main,
                ]}
              />
            }
          </Grid>
      </Container>
    </>
  );
}

export default ChartsPage;