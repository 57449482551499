import { useEffect } from 'react';
import { useForm } from "react-hook-form";
import { useSelector, useDispatch } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
// @mui
import { Stack, TextField, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';

import { history } from '../../../helpers';
import { authActions } from '../../../store';

// ----------------------------------------------------------------------

export default function ResetForm() {

  const dispatch = useDispatch();
    const authUser = useSelector(x => x.auth.user);
    const authError = useSelector(x => x.auth.error);

    useEffect(() => {
        // redirect to home if already logged in
        if (authUser) history.navigate('/');
        dispatch(authActions.deleteMessage())

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // form validation rules 
    const validationSchema = Yup.object().shape({
        email: Yup.string().email('Email invalide').required('Email obligatoire')
    });
    const formOptions = { resolver: yupResolver(validationSchema) };

    // get functions to build form with useForm() hook
    const { register, handleSubmit, formState } = useForm(formOptions);
    const { errors, isSubmitting } = formState;

    function onSubmit({ email }) {
        return dispatch(authActions.reset({ email }));
    }

  return (
    <>
      <Stack spacing={3}>
        <Typography variant="h5" sx={{ mt: 10, mb: 5 }}>
          Veuillez saisir votre email
        </Typography>
        <TextField 
          name="email" 
          label="Adresse Email" 
          {...register('email')}
          className={`form-control ${errors.email ? 'is-invalid' : ''}`}
        />
        <div className="invalid-feedback">{errors.email?.message}</div>
      </Stack>

      <LoadingButton 
        fullWidth size="large" 
        type="submit" 
        variant="contained" 
        onClick={handleSubmit(onSubmit)}
        disabled={isSubmitting}
      >
        {isSubmitting && <span className="spinner-border spinner-border-sm mr-1" />}
        Envoyer
      </LoadingButton>
      {authError &&
          <div className="alert alert-danger mt-3 mb-0">{authError.message}</div>
      }
    </>
  );
}
