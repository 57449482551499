import PropTypes from 'prop-types';
import { Alert } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import FormAmount from "../FormAmount/FormAmount";
import FormPrint from "../FormPrint/FormPrint";


const ConfirmDialog = (props) => {
  const { title, children, open, setOpen, onConfirm, source } = props;
  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="confirm-dialog"
    >
      <DialogTitle id="confirm-dialog" color="grey.600">{title}</DialogTitle>
      <DialogContent>
        {source==="print" && <FormPrint/>}
        {source==="yesno" && <Alert severity={source ==="ok" ? "error" : "success"}>{children}</Alert>}
        {source==="amount" && <FormAmount/>}
        {source==="ok" && <Alert severity="error">{children}</Alert>}
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="error"
          onClick={() => setOpen(false)}
        >
          {source !== "ok" ? "FERMER" : "OK"}
        </Button>
        {source !== "ok" && <Button
          variant="contained"
          color="success"
          onClick={() => {
            setOpen(false);
            onConfirm();
          }}
        >
          VALIDER
        </Button>}
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDialog;

ConfirmDialog.propTypes = {
  title: PropTypes.string,
  children: PropTypes.string,
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  onConfirm: PropTypes.func,
  source: PropTypes.string,
};