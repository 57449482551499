// component
import SvgColor from '../../../components/svg-color';

// ----------------------------------------------------------------------

const icon = (name) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const navConfig = [
  {
    title: 'Accueil',
    path: '/dashboard/app',
    icon: icon('ic_analytics'),
  },
  {
    title: 'Historique Pokou',
    path: '/dashboard/credits',
    icon: icon('ic_list'),
  },
  {
    title: 'Mes factures',
    path: '/dashboard/factures',
    icon: icon('ic_pdf'),
  },
  {
    title: 'Mes cotisations',
    path: '/dashboard/cotisations',
    icon: icon('ic_member'),
  },
  {
    title: 'Statistiques',
    path: '/dashboard/stats',
    icon: icon('ic_charts'),
  },
  {
    title: 'Déconnexion',
    path: '/dashboard/logout',
    icon: icon('ic_lock'),
  },
];

export default navConfig;
