import { Container } from "@mui/material";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
// import FormSumup from "../components/FormSumup/FormSumup";
import FormSumupWidget from "../components/FormSumup/FormSumupWidget";
import { creditActions, factureActions } from "../store";


function SumupPage() {
  const { amountSumup, tokenSumupLoaded, tokenSumup, sumupCheckoutStatus, idCheckoutSumup, sumupTransactionStatus } = useSelector(x => x.credits);
  const { user } = useSelector(x => x.auth);
  const { lastInsertId } = useSelector(x => x.factures);
  const { parametres } = useSelector(x => x.parametres);
  const dispatch = useDispatch();
  const description = 'Achat de pokou en ligne via Sumup';

  useEffect(() => {
    const externalScript = document.createElement('script');
    externalScript.src = "https://gateway.sumup.com/gateway/ecom/card/v2/sdk.js";
    externalScript.async = true;
    document.head.append(externalScript);

  return () => {
        externalScript.remove();
    }
  }, []);

  useEffect(() => {
    getSumupToken();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if(tokenSumupLoaded){
      getSumupCheckout();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tokenSumupLoaded]);

  useEffect(() => {
    if(sumupCheckoutStatus==='PAID' && sumupTransactionStatus==='SUCCESSFUL'){
      postFacture();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sumupCheckoutStatus, sumupTransactionStatus]);

  useEffect(() => {
    if(lastInsertId){
      postCredit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastInsertId]);

  async function getSumupToken(){
    await dispatch(creditActions.getSumupToken());
  }

  function postFacture(){
    dispatch(factureActions.postFacture({adherent:user.idu,description,montant:amountSumup}));
  }

  async function postCredit(){
    await dispatch(creditActions.postCredit({adherent:user.idu,credit:amountSumup*parametres[0].valeur,description,factureId:lastInsertId,type:1}));
    await dispatch(factureActions.deleteLastInsertId());
  }

  function getSumupCheckout(){
    const checkoutReference = user.idu+Date.now();
    dispatch(creditActions.getSumupCheckout({checkoutReference, token:tokenSumup, amount:amountSumup}));
  }

  return (
    <div>
      <Container maxWidth="sm">
        <h1>Achetez vos Pokou avec Sumup</h1>
        {idCheckoutSumup && <FormSumupWidget idCheckoutSumup={idCheckoutSumup} />}
      </Container>
    </div>
  );
}

export default SumupPage;