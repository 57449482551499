
export function repartition(credits) {
  let datas = '';
  const month = result(credits, 'permanent', 'description','Loyer mensuel');
  datas = month!== '' ? [...datas,month] : datas;
  const print = result(credits, 'imprimé', 'description','Impressions');
  datas = print!== '' ? [...datas,print] : datas;
  const day = result(credits, 'Journée', 'description','Journées');
  datas = day!== '' ? [...datas,day] : datas;
  const halfDay = result(credits,'Demi', 'description','Demi-journées');
  datas = halfDay!== '' ? [...datas,halfDay] : datas;
  const adhesion = result(credits, 'Cotisation', 'description','Cotisation annuelle');
  datas = adhesion!== '' ? [...datas,adhesion] : datas;
  return datas;
}

function result(credits, search, searchKey,label) {
  let dataFilter = '';
  if(searchKey==='description'){
    dataFilter = credits.filter((element) => {
      if (element[searchKey].includes(search)) {
        return true;
      }
        return false;
    });
  }else{
    dataFilter = credits.filter((element) => {
      if (element[searchKey]===search[0] || element[searchKey]===search[1]) {
        return true;
      }
        return false;
    });
  }
  let data = '';
  if(dataFilter){
    let sum = 0;
    dataFilter.forEach(value => {
      sum += value.credit;
    });
    if (sum !== 0){
      data = {label,value:Math.abs(sum)};
      return data;
    }
  }
  return data;
}
