import { useNavigate, useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
// routes
import Router from './routes';
// theme
import ThemeProvider from './theme';
// components
import ScrollToTop from './components/scroll-to-top';
import { history } from './helpers';
import { StyledChart } from './components/chart';
import { authActions } from './store';

// ----------------------------------------------------------------------

export default function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    // check refreshtoken
    const loadUser = async () => {
      dispatch(authActions.refreshToken())
    };
    loadUser();
    // eslint-disable-next-line
   } , []);
  history.navigate = useNavigate();
  history.location = useLocation();
  return (
    <ThemeProvider>
      <ScrollToTop />
      <StyledChart />
          <Router />
    </ThemeProvider>
  );
}

