import React, { useEffect } from 'react';
import { PDFViewer, StyleSheet } from '@react-pdf/renderer';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import PdfPage from './PdfPage';
import { factureActions } from '../store';
// Create styles
const styles = StyleSheet.create({
    viewer: {
      width: window.innerWidth,
      height: window.innerHeight,
    },
  });

  export default function ViewerPdf() {
  const { user } = useSelector(x => x.auth);
  const dispatch = useDispatch();
  useEffect(() => {
    if(user)
    initFactures(user);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  function initFactures(user){
    dispatch(factureActions.getAll(user.idu));
  }
  const { id } = useParams();
  const { factures, facturesLoad } = useSelector(x => x.factures);
  let facture;
  if(facturesLoad){
    facture = factures.find(item => item.id === Number(id))
  }
  return (
      facturesLoad  &&
        <PDFViewer style={styles.viewer}>
          <PdfPage facture={facture} societe={user.societe} />
       </PDFViewer>
  )
}