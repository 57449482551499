import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { fetchWrapper } from '../helpers';

// create slice

const name = 'adhesions';
const initialState = createInitialState();
const extraActions = createExtraActions();
const extraReducers = createExtraReducers();
const reducers = createReducers();
const slice = createSlice({ name, initialState, reducers, extraReducers });

// exports

export const adhesionActions = { ...slice.actions, ...extraActions };
export const adhesionsReducer = slice.reducer;

// implementation

function createInitialState() {
    return {
        adhesions: {},
        adhesiondLoad:false,
        error: null,
        adherentValid:false,
        messageAdhesion:'',
        dateAdhesion:'',
        adhesionCost:0
    }
}

function createReducers() {
  return{
    adhesionValid,
    getAdhesionCost
  }
  function adhesionValid(state, action){
    state.adherentValid= action.payload.newAdherentValid;
    state.messageAdhesion=action.payload.newMessageAdhesion;
    state.dateAdhesion=action.payload.newDateAdhesion;
  }
  function getAdhesionCost(state, action){
    state.adhesionCost= action.payload;
  }
}

function createExtraActions() {

    const baseUrl = `${process.env.REACT_APP_API_URL}/api/adhesions/`;

    return {
      getAll: getAll(),
      postAdhesion: postAdhesion()
    };    

    function getAll() {
        return createAsyncThunk(
            `${name}/getAll`,
            async (user) => fetchWrapper.get(baseUrl+user)
        );
    }
    function postAdhesion() {
      return createAsyncThunk(
          `${name}/postAdhesion`,
          async ({ adherent, montant, idcredit }) => fetchWrapper.post(baseUrl,{ adherent, montant, idcredit },adherent)
      );
    }
}

function createExtraReducers() {
    return {
        ...getAll(),
        ...postAdhesion()
    };

    function getAll() {
        const { pending, fulfilled, rejected } = extraActions.getAll;
        return {
            [pending]: (state, action) => {
              state.error = action.error;
              state.adhesiondLoad = false;
            },
            [fulfilled]: (state, action) => {
                state.adhesions = action.payload;
                state.adhesiondLoad = true;
            },
            [rejected]: (state, action) => {
              state.error = action.error;
            }
        };
    }
    function postAdhesion() {
      const { pending, fulfilled, rejected } = extraActions.postAdhesion;
      return {
          [pending]: (state, action) => {
            state.error = action.error;
            state.adhesiondLoad= false;
          },
          [fulfilled]: (state) => {
              state.adhesiondLoad= true;
          },
          [rejected]: (state, action) => {
            state.error = action.error;
            state.adhesiondLoad= false;
          }
      };
    }
}
