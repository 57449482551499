import { Helmet } from 'react-helmet-async';
// @mui
import { Grid, Container, Typography, Button } from '@mui/material';
// components
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import Iconify from '../components/iconify';
// sections
import {
  AppWidgetSummary,
} from '../sections/@dashboard/app';
import { pricing } from '../utils/formatNumber';
import TableCredits from '../sections/@dashboard/table/TableCredits';
import ConfirmDialog from '../components/ConfirmDialog/ConfirmDialog';
import { authActions, creditActions } from '../store';
import { history } from '../helpers';
import ButtonPayAdhesion from '../components/ButtonPayAdhesion/ButtonPayAdhesion';

// ----------------------------------------------------------------------

export default function DashboardAppPage() {
  // Confirm dialog boxes
  const [confirmOpenDay, setConfirmOpenDay] = useState(false);
  const [confirmOpenHalfday, setConfirmOpenHalfDay] = useState(false);
  const [confirmOpenPrint, setConfirmOpenPrint] = useState(false);
  const [confirmOpenSolde, setConfirmOpenSolde] = useState(false);
  const [confirmOpenAmount, setConfirmOpenAmount] = useState(false);
  //
  const { user, dayCost, halfDayCost } = useSelector(x => x.auth);
  const { permanent } = useSelector(x => x.permanent);
  const { solde, loadCredits, credits, totalPrint, countPrint, factureId, amountSumup } = useSelector(x => x.credits);
  const { adhesiondLoad, adherentValid, messageAdhesion,dateAdhesion } = useSelector(x => x.adhesions);
  const { parametres, parametresLoad } = useSelector(x => x.parametres);
  const dispatch = useDispatch();
  let price = '';

  function getPrice(){
    // pricing (permanent, adhesion, journeeNa, journeeA, coef, mois)
      price = pricing (permanent, adherentValid, parametres[1].valeur, parametres[2].valeur, parametres[0].valeur, parametres[23].valeur, user.type2);
  }

  useEffect(() => {
    if(parametresLoad){
      getPrice();
      const tempPrice = price.split(' / ');
      const newDayCost = tempPrice[0];
      const newHalfDayCost = tempPrice[1];
      dispatch(authActions.getCost({dayCost:newDayCost,halfDayCost:newHalfDayCost}));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parametresLoad, adherentValid]);
  
  async function confirmDay(duration){
    let cost='';
    let description='';
    if(duration==='full'){
      cost = dayCost*-1;
      description = 'Journée coworking';
    }else{
      cost = halfDayCost*-1;
      description = 'Demi journée coworking'
    }
    if(solde > cost){
      await dispatch(creditActions.postCredit({adherent:user.idu,credit:cost,description,factureId,type:2}));
      await dispatch(creditActions.getSum(user.idu));
      await dispatch(creditActions.getAll(user.idu));
      await dispatch(creditActions.deleteLastInsertId());
    }
  }
  async function confirmPrint(){
    if(solde > totalPrint && totalPrint > 0){
      const description = `George a imprimé ${countPrint} page${countPrint > 1 ? 's' : ''}`;
      await dispatch(creditActions.postCredit({adherent:user.idu,credit:totalPrint*-1,description,factureId,type:6}));
      await dispatch(creditActions.getSum(user.idu));
      await dispatch(creditActions.getAll(user.idu));
      await dispatch(creditActions.deleteLastInsertId());
    }
  }

  function confirmAmount(){
    if(amountSumup && amountSumup > 0)
      history.navigate('/dashboard/sumup',{ replace: true });
  }

  return (
    <>
      <Helmet>
        <title> Dashboard | Espace Pokou | La:Matrice </title>
      </Helmet>

      <Container maxWidth="xl">
        <Typography variant="h4" sx={{ mb: 5 }} color="grey.600" >
          Bienvenue sur votre Espace Pokou
        </Typography>

        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={3}>
            {loadCredits && <AppWidgetSummary title="Mon solde Pokou" total={Number(solde)} icon={'bi:emoji-kiss'} color="secondary" />}
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            {parametresLoad && adhesiondLoad && <AppWidgetSummary title={permanent ? `Journée / Demi journée / Prix mensuel`:`Journée / Demi journée`} total={permanent ? `${dayCost} / ${halfDayCost} / ${parametres[0].valeur * parametres[23].valeur}`:`${dayCost} / ${halfDayCost}`} color="success" icon={'entypo:price-tag'} />}
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            {adhesiondLoad &&<AppWidgetSummary title={messageAdhesion} total={dateAdhesion} color={adherentValid ? 'warning' : 'error'} icon={'material-symbols:workspace-premium'} />}
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary title={permanent ? `Coworker Permanent`:`Coworker nomade`} total={"Vous êtes"} color="primary" icon={permanent ? 'iconoir:desk':'fluent:backpack-24-filled'} />
          </Grid>
          
          <Grid item xs={12} md={3} lg={3} marginTop={{ xs: 0, md: 3, lg: 7 }}>
            <Grid item marginTop={{ xs: 1, md: 3, lg: 3 }}>
              <Button 
                variant="contained" 
                endIcon={<Iconify icon="mdi:cash-100" />} 
                onClick={() => {
                  setConfirmOpenAmount(true);
                  dispatch(creditActions.resetAmountSumup());
                  }
                } 
                color="secondary" 
                fullWidth              
              >
                Acheter des Pokou
              </Button>
              <ConfirmDialog
                title="Veuillez saisir le montant en Euros"
                open={confirmOpenAmount}
                setOpen={setConfirmOpenAmount}
                onConfirm={()=>confirmAmount()}
                source="amount"
              />          
            </Grid>
            {!adherentValid && 
              <Grid item marginTop={{ xs: 1, md: 3, lg: 3 }}>
                <ButtonPayAdhesion />
              </Grid>
            }
            <Grid item marginTop={{ xs: 1, md: 3, lg: 7 }}>
              <Button variant="contained" endIcon={<Iconify icon="mdi:cards-heart" />} onClick={() => solde >= dayCost ? setConfirmOpenDay(true) : setConfirmOpenSolde(true)} fullWidth color="success">
                Valider une journée
              </Button>
              <ConfirmDialog
                title="Confirmez vous la validation d'une journée de coworking ?"
                open={confirmOpenDay}
                setOpen={setConfirmOpenDay}
                onConfirm={()=>confirmDay('full')}
                source="yesno"
                children={`${dayCost} pokou`}
              />          
            </Grid>
            <Grid item marginTop={{ xs: 1, md: 3, lg: 3 }}>
              <Button variant="contained" endIcon={<Iconify icon="mdi:heart-half-full" />} onClick={() => solde >= halfDayCost ? setConfirmOpenHalfDay(true) : setConfirmOpenSolde(true)} fullWidth color="success">
                Valider une demi journée
              </Button>
              <ConfirmDialog
                title="Confirmez vous la validation d'une demi journée de coworking ?"
                open={confirmOpenHalfday}
                setOpen={setConfirmOpenHalfDay}
                onConfirm={()=>confirmDay('half')}
                source="yesno"
                children={`${halfDayCost} pokou`}
              />          
            </Grid>
            <Grid item marginTop={{ xs: 1, md: 3, lg: 3 }}>
              <Button variant="contained" endIcon={<Iconify icon="material-symbols:print" />} onClick={() => setConfirmOpenPrint(true)} fullWidth color="success">
                Valider mes impressions
              </Button>
              <ConfirmDialog
                title="Validez vos impressions"
                open={confirmOpenPrint}
                setOpen={setConfirmOpenPrint}
                onConfirm={()=>confirmPrint()}
                source="print"
              />          
            </Grid>
          </Grid>

          {loadCredits && credits.length > 0 && <Grid item xs={12} md={9} lg={9}>
          <Typography variant="h4" sx={{ mb: 5 }} color="grey.600">
            Mes 5 dernières actions
          </Typography>
            <TableCredits />
          </Grid>
          }

            <ConfirmDialog
              title="Votre solde de Pokou est insuffisant"
              children={`${solde} pokou`}
              open={confirmOpenSolde}
              setOpen={setConfirmOpenSolde}
              source="ok"
            />     
          </Grid>
      </Container>
    </>
  );
}
