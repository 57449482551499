import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { fetchWrapper } from '../helpers';

// create slice

const name = 'permanent';
const initialState = createInitialState();
const extraActions = createExtraActions();
const extraReducers = createExtraReducers();
const slice = createSlice({ name, initialState, extraReducers });

// exports

export const permanentActions = { ...slice.actions, ...extraActions };
export const permanentReducer = slice.reducer;

// implementation

function createInitialState() {
    return {
        permanent: 0,
        error: null
    }
}

function createExtraActions() {

    const baseUrl = `${process.env.REACT_APP_API_URL}/api/permanent/`;

    return {
        getOne: getOne()
    };    

    function getOne() {
        return createAsyncThunk(
            `${name}/getOne`,
            async (user) => fetchWrapper.get(baseUrl+user)
        );
    }
}

function createExtraReducers() {
    return {
        ...getOne()
    };

    function getOne() {
        const { pending, fulfilled, rejected } = extraActions.getOne;
        return {
            [pending]: (state) => {
                state.permanent = 0;
            },
            [fulfilled]: (state, action) => {
                state.permanent = action.payload.etat;
            },
            [rejected]: (state, action) => {
              state.error = action.error;
            }
        };
    }
}
