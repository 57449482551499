import { alpha } from '@mui/material/styles';

// ----------------------------------------------------------------------

// SETUP COLORS
const GREY = {
  0: '#FFFFFF',
  100: '#F9FAFB',
  200: '#F4F6F8',
  300: '#DFE3E8',
  400: '#C4CDD5',
  500: '#919EAB',
  600: '#637381',
  700: '#454F5B',
  800: '#212B36',
  900: '#161C24',
};

const PRIMARY = {
  lighter: '#B6E5F7',
  light: '#91D9F3',
  main: '#3FBCEA',
  dark: '#169ACA',
  darker: '#0E6281',
  contrastText: '#fff',
};

const SECONDARY = {
  lighter: '#D7CBE1',
  light: '#AF97C3',
  main: '#70518C',
  dark: '#5D4375',
  darker: '#48345B',
  contrastText: '#fff',
};

const INFO = {
  lighter: '#FCEBD9',
  light: '#F9D7B4',
  main: '#F5BF84',
  dark: '#F3B068',
  darker: '#F09C42',
  contrastText: '#fff',
};

const SUCCESS = {
  lighter: '#CBE5C8',
  light: '#B1D8AC',
  main: '#7FBE76',
  dark: '#519948',
  darker: '#1E371A',
  contrastText: GREY[800],
};

const WARNING = {
  lighter: '#FFF7CD',
  light: '#FFE16A',
  main: '#CB5B69',
  dark: '#B78103',
  darker: '#7A4F01',
  contrastText: GREY[800],
};

const ERROR = {
  lighter: '#FFE7D9',
  light: '#FFA48D',
  main: '#FF4842',
  dark: '#B72136',
  darker: '#7A0C2E',
  contrastText: '#fff',
};

const palette = {
  common: { black: '#000', white: '#fff' },
  primary: PRIMARY,
  secondary: SECONDARY,
  info: INFO,
  success: SUCCESS,
  warning: WARNING,
  error: ERROR,
  grey: GREY,
  divider: alpha(GREY[500], 0.24),
  text: {
    primary: GREY[800],
    secondary: GREY[600],
    disabled: GREY[500],
  },
  background: {
    paper: '#fff',
    default: GREY[100],
    neutral: GREY[200],
  },
  action: {
    active: GREY[600],
    hover: alpha(GREY[500], 0.08),
    selected: alpha(GREY[500], 0.16),
    disabled: alpha(GREY[500], 0.8),
    disabledBackground: alpha(GREY[500], 0.24),
    focus: alpha(GREY[500], 0.24),
    hoverOpacity: 0.08,
    disabledOpacity: 0.48,
  },
};

export default palette;
