import { Container, InputAdornment, TextField } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { creditActions } from "../../store";
import Iconify from "../iconify";

const FormAmount = () =>{
  const { amountSumup } = useSelector(x => x.credits);
  const dispatch = useDispatch();

  function handleChange(name, value){
    dispatch(creditActions.updatePrintValue({name, value}));
  }

  return (
  <Container maxWidth="sm">
    <TextField
      autoFocus
      margin="dense"
      id="amountSumup"
      label="Montant"
      type="number"
      fullWidth
      variant="standard"
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <Iconify icon="iconoir:euro-square"/>
          </InputAdornment>
        )
      }}
      value={amountSumup}
      onInput = {(e) =>{
        e.target.value = Math.max(0, parseInt(e.target.value,10) ).toString().slice(0,3)
      }}
      onChange={(e)=>handleChange('amountSumup',e.target.value)}
    />
  </Container>
  )
}

export default FormAmount;