import { Button } from "@mui/material";
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import ConfirmDialog from "../ConfirmDialog/ConfirmDialog";
import Iconify from "../iconify/Iconify";
import { adhesionActions, creditActions } from '../../store';


function ButtonPayAdhesion() {
  const [confirmOpenAdhesion, setConfirmOpenAdhesion] = useState(false);
  const [confirmOpenSolde, setConfirmOpenSolde] = useState(false);
  const { user, type2 } = useSelector(x => x.auth);
  const { solde, lastInsertId, factureId } = useSelector(x => x.credits);
  const { adhesionCost } = useSelector(x => x.adhesions);
  const { parametres, parametresLoad } = useSelector(x => x.parametres);
  const dispatch = useDispatch();
  let newCost = 0;

  async function getAdhesionCost(){
    if(type2 ===2){
      newCost = (parametres[0].valeur * parametres[7].valeur) /2;
    }else{
      newCost = (parametres[0].valeur * parametres[7].valeur);
    }
    await dispatch(adhesionActions.getAdhesionCost(newCost));
  }
  useEffect(() => {
    if (parametresLoad) {
      getAdhesionCost();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parametresLoad]);

  useEffect(() => {
    if (lastInsertId) {
      const fetchData = async () => {
        await dispatch(adhesionActions.postAdhesion({adherent:user.idu,montant:adhesionCost/Number(parametres[0].valeur),idcredit:lastInsertId}));
        await dispatch(creditActions.deleteLastInsertId());
        await dispatch(adhesionActions.getAll(user.idu));
      }
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastInsertId]);

  

  async function confirmAdhesion(){
    if(solde >= adhesionCost){
      const year = new Date().getFullYear();
      const description = `Cotisation annuelle ${year}`;
      await dispatch(creditActions.postCredit({adherent:user.idu,credit:adhesionCost*-1,description,factureId,type:8}));
      await dispatch(creditActions.getSum(user.idu));
      await dispatch(creditActions.getAll(user.idu));
    }
  }
  return (
    <>
      <Button variant="outlined" endIcon={<Iconify icon="material-symbols:workspace-premium" />} 
        onClick={() => solde >= adhesionCost ? setConfirmOpenAdhesion(true) : setConfirmOpenSolde(true)} 
        fullWidth color="warning">
        Payer ma cotisation
      </Button>
      <ConfirmDialog
        title='Confirmez vous votre adhésion :'
        children={`${adhesionCost} pokou`}
        open={confirmOpenAdhesion}
        setOpen={setConfirmOpenAdhesion}
        onConfirm={()=>confirmAdhesion()}
        source="yesno"
      />
      <ConfirmDialog
        title="Votre solde de Pokou est insuffisant"
        children={`${solde} pokou`}
        open={confirmOpenSolde}
        setOpen={setConfirmOpenSolde}
        source="ok"
      />
    </>
  );
}

export default ButtonPayAdhesion;