import { useState, useEffect } from 'react';
import { useForm } from "react-hook-form";
import { useSelector, useDispatch } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
// @mui
import { Stack, IconButton, InputAdornment, TextField, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import { useLocation } from 'react-router-dom';
import Iconify from '../../../components/iconify';

import { history } from '../../../helpers';
import { authActions } from '../../../store';

// ----------------------------------------------------------------------

export default function UpdateForm() {

  const [showPassword, setShowPassword] = useState(false);

  const useQuery = () => new URLSearchParams(useLocation().search);
  const query = useQuery();
  const token = query.get('token');

  const dispatch = useDispatch();
    const authUser = useSelector(x => x.auth.user);
    const authError = useSelector(x => x.auth.error);

    useEffect(() => {
        // redirect to home if already logged in
        if (authUser) history.navigate('/');

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // form validation rules 
    const validationSchema = Yup.object().shape({
        password: Yup.string().min(6, 'Minimum 6 caractères').max(255).required('Mot de passe obligatoire'),
        passwordconfirm: Yup.string().oneOf([Yup.ref('password'), null], 'Les mots de passes ne sont pas identiques')
    });
    const formOptions = { resolver: yupResolver(validationSchema) };

    // get functions to build form with useForm() hook
    const { register, handleSubmit, formState } = useForm(formOptions);
    const { errors, isSubmitting } = formState;

    function onSubmit({ token, password }) {
        return dispatch(authActions.updatepass({ token, password }));
    }

  return (
    <>
      <Stack spacing={3}>
      <Typography variant="h5" sx={{ mt: 10, mb: 5 }}>
          Veuillez définir un nouveau mot de passe
        </Typography>
        <TextField
          name="password"
          label="Mot de passe"
          type={showPassword ? 'text' : 'password'}
          {...register('password')}
          className={`form-control ${errors.password ? 'is-invalid' : ''}`}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <div className="invalid-feedback">{errors.password?.message}</div>
        <TextField
          name="passwordconfirm"
          label="Confirmez le mot de passe"
          type={showPassword ? 'text' : 'password'}
          {...register('passwordconfirm')}
          className={`form-control ${errors.passwordconfirm ? 'is-invalid' : ''}`}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <TextField
          name="token"
          type="hidden"
          className='hidden'
          {...register('token', { value: token })}
          variant="standard"
          InputProps={{
            disableUnderline: true,
          }}
        />
        <div className="invalid-feedback">{errors.passwordconfirm?.message}</div>
      </Stack>

      {token && <LoadingButton 
        fullWidth size="large" 
        type="submit" 
        variant="contained" 
        onClick={handleSubmit(onSubmit)}
        disabled={isSubmitting}
        >
        {isSubmitting && <span className="spinner-border spinner-border-sm mr-1" />}
        Envoyer
        </LoadingButton>
      }
      {authError &&
          <div className="alert alert-danger mt-3 mb-0">{authError.message}</div>
      }
    </>
  );
}
