import { useDispatch, useSelector } from "react-redux";
import { authActions } from "../store";


function LogoutPage() {
  const dispatch = useDispatch();
  const { user } = useSelector(x => x.auth);
  dispatch(authActions.logout(user.idu));

  return (
    <div />
  );
}

export default LogoutPage;