import { useState, useEffect } from 'react';
import { useForm } from "react-hook-form";
import { useSelector, useDispatch } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
// @mui
import { Link, Stack, IconButton, InputAdornment, TextField, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import Iconify from '../../../components/iconify';

import { history } from '../../../helpers';
import { authActions } from '../../../store';

// ----------------------------------------------------------------------

export default function LoginForm() {

  const [showPassword, setShowPassword] = useState(false);

  const dispatch = useDispatch();
    const authUser = useSelector(x => x.auth.user);
    const authError = useSelector(x => x.auth.error);

    useEffect(() => {
        // redirect to home if already logged in
        if (authUser) history.navigate('/');

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // form validation rules 
    const validationSchema = Yup.object().shape({
        email: Yup.string().email('Email invalide').required('Email obligatoire'),
        password: Yup.string().required('Mot de passe obligatoire')
    });
    const formOptions = { resolver: yupResolver(validationSchema) };

    // get functions to build form with useForm() hook
    const { register, handleSubmit, formState } = useForm(formOptions);
    const { errors, isSubmitting } = formState;

    function onSubmit({ email, password }) {
        return dispatch(authActions.login({ email, password }));
    }

  return (
    <>
      <Stack spacing={3}>
        <Typography variant="h5" sx={{ mt: 10, mb: 5 }}>
          Connectez-vous à votre Espace Pokou
        </Typography>
        <TextField 
          name="email" 
          label="Adresse Email" 
          {...register('email')}
          className={`form-control ${errors.email ? 'is-invalid' : ''}`}
        />
        <div className="invalid-feedback">{errors.email?.message}</div>
        <TextField
          name="password"
          label="Mot de passe"
          type={showPassword ? 'text' : 'password'}
          {...register('password')}
          className={`form-control ${errors.password ? 'is-invalid' : ''}`}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
          onKeyPress={(event) => {
            if (event.key === 'Enter')
              handleSubmit(onSubmit)();
          }}
        />
        <div className="invalid-feedback">{errors.password?.message}</div>
      </Stack>

      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
        <Link variant="subtitle2" underline="hover" onClick={()=>history.navigate('/login?reset=true')} component="button">
          Mot de passe oublié?
        </Link>
      </Stack>

      <LoadingButton 
        fullWidth size="large" 
        type="submit" 
        variant="contained" 
        onClick={handleSubmit(onSubmit)}
        disabled={isSubmitting}
      >
        {isSubmitting && <span className="spinner-border spinner-border-sm mr-1" />}
        Se connecter
      </LoadingButton>
      {authError &&
          <div className="alert alert-danger mt-3 mb-0">{authError.message}</div>
      }
    </>
  );
}
