import PropTypes from 'prop-types';
import { Navigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { useEffect } from 'react';
import { history } from '../helpers';
import { creditActions, permanentActions, adhesionActions, parametreActions } from '../store';
import { diffDays, fDate } from '../utils/formatTime';


export { PrivateRoute };

function PrivateRoute({ children }) {
    const { user } = useSelector(x => x.auth);
    const { adhesions, adhesiondLoad} = useSelector(x => x.adhesions);
    const dispatch = useDispatch();
    useEffect(() => {
      if(user)
      initDatas(user);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(() => {
      if(user){
        let newMessageAdhesion='';
        let newDateAdhesion = '';
        let newAdherentValid = false;
        if(adhesiondLoad){
          if(adhesions[0]){
            const arrayAdhesion = adhesions[0];
            // check idfacture
            let temp = '';
            if(arrayAdhesion.datefacture){
              temp = diffDays(arrayAdhesion.datefacture);
              newDateAdhesion = fDate(arrayAdhesion.datefacture,'dd/MM/yyyy');
            }else{
              temp = diffDays(arrayAdhesion.datecredit);
              newDateAdhesion = fDate(arrayAdhesion.datecredit,'dd/MM/yyyy');
            }
            const days = temp.split(" ");
            const nbDays = days[0];
            if (Number(nbDays) > 365){
              newMessageAdhesion = "Votre adhésion n'est plus valide";
            }else{
              newMessageAdhesion = "Adhésion valide";
              newAdherentValid = true
            }
          }else{
            newMessageAdhesion="Vous n'êtes pas encore adhérent";
            newDateAdhesion="Non adhérent";
          }
          dispatch(adhesionActions.adhesionValid({newMessageAdhesion,newDateAdhesion,newAdherentValid}));
        }
      }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [adhesiondLoad]);
    function initDatas(user){
      dispatch(permanentActions.getOne(user.idu));
      dispatch(creditActions.getSum(user.idu));
      dispatch(creditActions.getAll(user.idu));
      dispatch(adhesionActions.getAll(user.idu));
      dispatch(parametreActions.getAll(user.idu));
    }
    if (!user) {
        // not logged in so redirect to login page with the return url
        return <Navigate to="/login" state={{ from: history.location }} />
    }
    
    // authorized so return child components
    return children;
}

PrivateRoute.propTypes = {
  children: PropTypes.node,
};