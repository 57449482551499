import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { fetchWrapper, history } from '../helpers';

// create slice

const name = 'credits';
const initialState = createInitialState();
const extraActions = createExtraActions();
const extraReducers = createExtraReducers();
const reducers = createReducers();
const slice = createSlice({ name, initialState, reducers, extraReducers });

// exports

export const creditActions = { ...slice.actions, ...extraActions };
export const creditsReducer = slice.reducer;

// implementation

function createInitialState() {
    return {
        solde: '',
        credits: {},
        loadCredits: false,
        error: null,
        a4nb: '',
        a4color: '',
        a3nb: '',
        a3color:'',
        totalPrint: 0,
        countPrint:0,
        lastInsertId: 0,
        amountSumup:'',
        tokenSumup:'',
        tokenSumupLoaded:false,
        idCheckoutSumup:'',
        sumupCheckoutStatus:'',
        sumupTransactionStatus:'',
        factureId:0
    }
}

function createReducers() {
  return{
    updatePrintValue,
    addPrint,
    deleteLastInsertId,
    resetAmountSumup,
  }
  function updatePrintValue(state,action){
    state[action.payload.name]= action.payload.value;
  }
  function addPrint(state,action){
    state.totalPrint= action.payload.value;
    state.countPrint= action.payload.count;
  }
  function deleteLastInsertId(state){
    state.lastInsertId= 0;
  }
  function resetAmountSumup(state){
    state.amountSumup= '';
    state.idCheckoutSumup='';
  }
}

function createExtraActions() {

    const baseUrl = `${process.env.REACT_APP_API_URL}/api/credits/`;
    const sumupTokenUrl = 'https://api.sumup.com/token';
    const sumupCheckoutUrl = 'https://api.sumup.com/v0.1/checkouts';

    return {
        getSum: getSum(),
        getAll: getAll(),
        postCredit: postCredit(),
        getSumupToken:getSumupToken(),
        getSumupCheckout:getSumupCheckout(),
        completeCheckout:completeCheckout(),
        getCheckout:getCheckout(),
    };    

    function getSum() {
        return createAsyncThunk(
            `${name}/getSum`,
            async (user) => fetchWrapper.get(`${baseUrl+user}?somme=true`)
        );
    }
    function getAll() {
      return createAsyncThunk(
          `${name}/getAll`,
          async (user) => fetchWrapper.get(baseUrl+user)
      );
    }
    function postCredit() {
      return createAsyncThunk(
          `${name}/postCredit`,
          async ({ adherent, credit, description, factureId, type }) => fetchWrapper.post(baseUrl,{ adherent, credit, description, type, facture:factureId },adherent)
      );
    }
    function getSumupToken() {
      return createAsyncThunk(
        `${name}/getSumupToken`,
        async () => fetchWrapper.postSumup(sumupTokenUrl,{ grant_type:'client_credentials', client_id:process.env.REACT_APP_SUMUP_CLIENT_ID, client_secret:process.env.REACT_APP_SUMUP_CLIENT_SECRET })
      );
    }
    function getSumupCheckout() {
      return createAsyncThunk(
        `${name}/getSumupCheckout`,
        async ({checkoutReference, amount, token} ) => fetchWrapper.postSumup(sumupCheckoutUrl,{ checkout_reference:checkoutReference, amount, currency: "EUR", merchant_code:process.env.REACT_APP_SUMUP_MERCHANT_CODE, return_url:process.env.REACT_APP_SUMUP_RETURN_URL },token)
      );
    }
    function completeCheckout() {
      return createAsyncThunk(
        `${name}/completeCheckout`,
        async ({name, number, expiryMonth, expiryYear, cvv, idCheckoutSumup, token} ) => fetchWrapper.putSumup(`${sumupCheckoutUrl}/${idCheckoutSumup}`,{ payment_type: "card",
        card: {name, number, expiry_month:expiryMonth, expiry_year:expiryYear, cvv }},token)
      );
    }
    function getCheckout() {
      return createAsyncThunk(
          `${name}/getCheckout`,
          async ({ id, tokenSumup }) => fetchWrapper.getSumup(`${sumupCheckoutUrl}/${id}`,'',tokenSumup)
      );
    }
}

function createExtraReducers() {
    return {
        ...getSum(),
        ...getAll(),
        ...postCredit(),
        ...getSumupToken(),
        ...getSumupCheckout(),
        ...completeCheckout(),
        ...getCheckout(),
    };

    function getSum() {
        const { pending, fulfilled, rejected } = extraActions.getSum;
        return {
            [pending]: (state, action) => {
              state.error = action.error;
            },
            [fulfilled]: (state, action) => {
                state.solde = action.payload.solde;
            },
            [rejected]: (state, action) => {
              state.error = action.error;
            }
        };
    }
    function getAll() {
      const { pending, fulfilled, rejected } = extraActions.getAll;
      return {
          [pending]: (state, action) => {
            state.error = action.error;
            state.loadCredits= false;
          },
          [fulfilled]: (state, action) => {
              state.credits = action.payload;
              state.loadCredits= true;
          },
          [rejected]: (state, action) => {
            state.error = action.error;
            state.loadCredits= false;
          }
      };
    }
    function postCredit() {
      const { pending, fulfilled, rejected } = extraActions.postCredit;
      return {
          [pending]: (state, action) => {
            state.error = action.error;
            state.loadCredits= false;
          },
          [fulfilled]: (state, action) => {
              state.loadCredits= true;
              if(action.payload.type === 6){
                state.a4nb = '';
                state.a4color = '';
                state.a3nb = '';
                state.a3color = '';
                state.totalPrint = 0;
                state.countPrint = 0;
              }
              if(action.payload.type === 1){
                state.amountSumup = '';
                state.sumupCheckoutStatus = '';
                state.idCheckoutSumup = '';
                state.sumupTransactionStatus = '';
                history.navigate('/', { replace: true });
              }
              if(action.payload.type === 8){
                state.lastInsertId = action.payload.id;
              }
          },
          [rejected]: (state, action) => {
            state.error = action.error;
            state.loadCredits= false;
          }
      };
    }
    function getSumupToken() {
      const { pending, fulfilled, rejected } = extraActions.getSumupToken;
      return {
          [pending]: (state, action) => {
            state.error = action.error;
          },
          [fulfilled]: (state, action) => {
              state.tokenSumup = action.payload.access_token;
              state.sumupCheckoutStatus = '';
              state.tokenSumupLoaded=true;
          },
          [rejected]: (state, action) => {
            state.error = action.error;
          }
      };
    }
    function getSumupCheckout() {
      const { pending, fulfilled, rejected } = extraActions.getSumupCheckout;
      return {
          [pending]: (state, action) => {
            state.error = action.error;
          },
          [fulfilled]: (state, action) => {
              state.idCheckoutSumup = action.payload.id;
          },
          [rejected]: (state, action) => {
            state.error = action.error;
          }
      };
    }
    function completeCheckout() {
      const { pending, fulfilled, rejected } = extraActions.completeCheckout;
      return {
          [pending]: (state, action) => {
            state.error = action.error;
          },
          [fulfilled]: (state, action) => {
              state.sumupCheckoutStatus = action.payload.status;
              state.idCheckoutSumup = '';
              state.tokenSumup='';
              state.tokenSumupLoaded=false;
              state.amountSumup = '';
          },
          [rejected]: (state, action) => {
            state.error = action.error;
          }
      };
    }
    function getCheckout() {
      const { pending, fulfilled, rejected } = extraActions.getCheckout;
      return {
          [pending]: (state, action) => {
            state.error = action.error;
          },
          [fulfilled]: (state, action) => {
              state.sumupCheckoutStatus = action.payload.status;
              if(action.payload.status === 'PAID'){
                state.sumupTransactionStatus = action.payload.transactions[0].status;
              }
          },
          [rejected]: (state, action) => {
            state.error = action.error;
          }
      };
    }
}
