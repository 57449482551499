import { Alert, Container, InputAdornment, Stack, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { creditActions } from "../../store";
import Iconify from "../iconify";

const FormPrint = () =>{
  const [error, setError] = useState(false);
  const { parametres } = useSelector(x => x.parametres);
  const { solde, a4nb, a4color, a3nb, a3color, totalPrint } = useSelector(x => x.credits);
  const dispatch = useDispatch();

  useEffect(() => {
    const costA4Nb = a4nb * parametres[19].valeur;
    const costA4Color = a4color * parametres[20].valeur;
    const costA3Nb = a3nb * parametres[21].valeur;
    const costA3Color = a3color * parametres[22].valeur;
    const totalPrint = costA4Nb + costA4Color + costA3Nb + costA3Color;
    const countPrint = Number(a4nb) + Number(a4color) + Number(a3nb) + Number(a3color);

    dispatch(creditActions.addPrint({value:totalPrint, count:countPrint}));

    if(totalPrint > solde){
      setError(true);
    }else{
      setError(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [a4nb, a4color, a3nb, a3color]);

  function handleChange(name, value){
    dispatch(creditActions.updatePrintValue({name, value}));
  }

  return (
  <Container maxWidth="sm">
    <TextField
      autoFocus
      margin="dense"
      id="a4nb"
      label={`Impressions A4 Noir et blanc : ${parametres[19].valeur} Pokou`}
      type="number"
      fullWidth
      variant="standard"
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <Iconify icon="material-symbols:print"/>
          </InputAdornment>
        )
      }}
      value={a4nb}
      onInput = {(e) =>{
        e.target.value = Math.max(0, parseInt(e.target.value,10) ).toString().slice(0,3)
      }}
      onChange={(e)=>handleChange('a4nb',e.target.value)}
    />
    <TextField
      margin="dense"
      id="a4color"
      label={`Impressions A4 Couleur : ${parametres[20].valeur} Pokou`}
      type="number"
      fullWidth
      variant="standard"
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <Iconify icon="material-symbols:print" color="green"/>
          </InputAdornment>
        ),
      }}
      value={a4color}
      onInput = {(e) =>{
        e.target.value = Math.max(0, parseInt(e.target.value,10) ).toString().slice(0,3)
      }}
      onChange={(e)=>handleChange('a4color',e.target.value)}
    />
    <TextField
      margin="dense"
      id="a3nb"
      label={`Impressions A3 Noir et blanc : ${parametres[21].valeur} Pokou`}
      type="number"
      fullWidth
      variant="standard"
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <Iconify icon="material-symbols:print"/>
          </InputAdornment>
        ),
      }}
      value={a3nb}
      onInput = {(e) =>{
        e.target.value = Math.max(0, parseInt(e.target.value,10) ).toString().slice(0,3)
      }}
      onChange={(e)=>handleChange('a3nb',e.target.value)}
    />
    <TextField
      margin="dense"
      id="a3color"
      label={`Impressions A3 Couleur : ${parametres[22].valeur} Pokou`}
      type="number"
      fullWidth
      variant="standard"
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <Iconify icon="material-symbols:print" color="green"/>
          </InputAdornment>
        ),
      }}
      value={a3color}
      onInput = {(e) =>{
        e.target.value = Math.max(0, parseInt(e.target.value,10) ).toString().slice(0,3)
      }}
      onChange={(e)=>handleChange('a3color',e.target.value)}
    />
    <Stack direction="row" spacing={2} justifyContent="flex-end">
        <TextField
          margin="dense"
          id="totalPrint"
          label="Total Pokou"
          type="number"
          variant="outlined"
          value={totalPrint}
          InputProps={{
            inputProps: {
                style: { textAlign: "right" },
            }
          }}
        />
    </Stack>
    {error && <Alert severity="error">Votre solde de Pokou est insuffisant</Alert>}
  </Container>
  )
}

export default FormPrint;