import { configureStore } from '@reduxjs/toolkit';

import { authReducer } from './auth.slice';
import { permanentReducer } from './permanent.slice';
import { creditsReducer } from './credits.slice';
import { adhesionsReducer } from './adhesions.slice';
import { parametresReducer } from './parametres.slice';
import { facturesReducer } from './factures.slice';

export * from './auth.slice';
export * from './permanent.slice';
export * from './credits.slice';
export * from './adhesions.slice';
export * from './parametres.slice';
export * from './factures.slice';

export const store = configureStore({
    reducer: {
        auth: authReducer,
        permanent: permanentReducer,
        credits: creditsReducer,
        adhesions: adhesionsReducer,
        parametres: parametresReducer,
        factures: facturesReducer
    },
});