import PropTypes from 'prop-types';
import { Alert, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { creditActions } from "../../store";

function FormSumupWidget({idCheckoutSumup}) {
    const dispatch = useDispatch();
    const { amountSumup, tokenSumup } = useSelector(x => x.credits);
    const [error, seterror] = useState(false);

    useEffect(() => {
      if (typeof window.SumUpCard !== 'undefined') {
        displayForm(idCheckoutSumup);
      }
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []);

    function displayForm (idcheckout){
        const {SumUpCard} = window;
        SumUpCard.mount({
            checkoutId: idcheckout,
            onResponse (type, body) {
                console.log('Type', type);
                console.log('Body', body);
                if (type==='success'){
                  getCheckout(body.id);
                }else if(type==='error' || type==='invalid'){
                  seterror(true);
                }
            },
        });
    }

    function getCheckout(id){
        dispatch(creditActions.getCheckout({id, tokenSumup}));
    }

    return (
        <>
            <Typography variant="h4" sx={{ mb: 5 }} color="success.main" >
            Payez votre achat de Pokou de : {amountSumup} €
            </Typography>
            {error && <Alert severity="error">Une erreur est survenue, vérifiez votre saisie</Alert>}
            <div id="sumup-card" />
        </>
    );
}

export default FormSumupWidget;

FormSumupWidget.propTypes = {
  idCheckoutSumup: PropTypes.string,
};