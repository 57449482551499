import { Navigate, useRoutes } from 'react-router-dom';
import { LinearProgress } from '@mui/material';
import { useSelector } from 'react-redux';
import { PrivateRoute } from './components';
// layouts
import DashboardLayout from './layouts/dashboard';
import SimpleLayout from './layouts/simple';
//
import CreditsPage from './pages/CreditsPage';
import LoginPage from './pages/LoginPage';
import Page404 from './pages/Page404';
import DashboardAppPage from './pages/DashboardAppPage';
import FacturesPage from './pages/FacturesPage';
import AdhesionsPage from './pages/AdhesionsPage';
import ViewerPdf from './pages/ViewerPdf';
import LogoutPage from './pages/LogoutPage';
import SumupPage from './pages/SumupPage';
import ChartsPage from './pages/ChartsPage';

// ----------------------------------------------------------------------

export default function Router() {
  const { userLoading } = useSelector(x => x.auth);
  const routes = useRoutes([
    {
      path: '/dashboard',
      element: userLoading ?<LinearProgress /> : <PrivateRoute><DashboardLayout /></PrivateRoute>,
      children: [
        { element: <Navigate to="/dashboard/app" />, index: true },
        { path: 'app', element: <DashboardAppPage /> },
        { path: 'credits', element: <CreditsPage /> },
        { path: 'factures', element: <FacturesPage /> },
        { path: 'cotisations', element: <AdhesionsPage /> },
        { path: 'sumup', element: <SumupPage /> },
        { path: 'stats', element: <ChartsPage /> },
        { path: 'logout', element: <LogoutPage /> },
      ],
    },
    {
      path: 'login',
      element: userLoading ?<LinearProgress /> : <LoginPage />,
    },
    {
      element: <SimpleLayout />,
      children: [
        { element: <Navigate to="/dashboard/app" />, index: true },
        { path: '404', element: <Page404 /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    { path: 'pdf/:id', element: userLoading ?<LinearProgress /> : <PrivateRoute><ViewerPdf /></PrivateRoute> },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);

  return routes;
}
