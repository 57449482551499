import numeral from 'numeral';

// ----------------------------------------------------------------------

export function fNumber(number) {
  return numeral(number).format();
}

export function fCurrency(number) {
  const format = number ? numeral(number).format('$0,0.00') : '';

  return result(format, '.00');
}

export function fPercent(number) {
  const format = number ? numeral(Number(number) / 100).format('0.0%') : '';

  return result(format, '.0');
}

export function fShortenNumber(number) {
  const format = number ? numeral(number).format('0.00a') : '';

  return result(format, '.00');
}

export function fData(number) {
  const format = number ? numeral(number).format('0.0 b') : '';

  return result(format, '.0');
}

function result(format, key = '.00') {
  const isInteger = format.includes(key);

  return isInteger ? format.replace(key, '') : format;
}

export function pricing (permanent, adhesion, journeeNa, journeeA, coef, mois, type){
  let month = '';
  if(permanent===1){
    month = ` / ${mois*coef}`;
  }
    if(adhesion){
      if(type===2){
        return `${(journeeA*coef)/2} / ${(journeeA*coef)/4}${month}`;
      }
      return `${journeeA*coef} / ${(journeeA*coef)/2}${month}`;
    }
    if(type===2){
      return `${(journeeNa*coef)/2} / ${(journeeNa*coef)/4}${month}`;
    }
      return `${journeeNa*coef} / ${(journeeNa*coef)/2}${month}`;
}