import { Page, Text, View, Document, StyleSheet, Image  } from '@react-pdf/renderer';
import PropTypes from 'prop-types';

// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    backgroundColor: '#ffffff',
    margin:20,
    fontSize:'12px'
  },
  section: {
    flexDirection: 'row'
  },
  sectionColumn: {
    flexDirection: 'column'
  },
  image: {
    width: '30%',
    height: 'auto',
    marginBottom:30
  },
  titre:{
    color: '#42a39c', fontWeight:900, width: '60%', textAlign:'right', fontSize:'25px'
  },
  headTable:{
    backgroundColor: '#42a39c', color:'#ffffff', padding:'5px 30px'
  },
  headTable2:{
    backgroundColor: '#42a39c', color:'#ffffff', padding:'5px 10px'
  },
  datasTable:{
    padding:'5px 15px'
  },
  user:{
    marginLeft:'45%',
    marginBottom:30
  },
  block:{
    display:'block',
  },
  totaux:{
    width:'25%',
    marginLeft:'50%',
  },
  totaux2:{
    width:'18%',
    backgroundColor:'#e1e1e1',
    textAlign:'center',
  },
  copyright:{
    fontSize:'10px',
    width:'95%',
    textAlign:'center',
    color:'#999999',
    position:'absolute',
    bottom:60
  }        
});

// Create Document Component
const PdfPage = ({ facture, societe }) => (
  <Document title={facture.numero}>
    <Page size="A4" style={styles.page}>
      <View style={styles.section}>
      <Image
        style={styles.image}
        src="/assets/logo-kd.jpg"
      /> 
        <Text style={styles.titre}>FACTURE</Text>
      </View>
      <View style={styles.section}>
        <Text style={styles.headTable}>Date</Text>
        <Text style={styles.headTable}>Numéro</Text>
      </View>
      <View style={styles.section}>
        <Text style={styles.datasTable}>{facture.datefacture}</Text>
        <Text style={styles.datasTable}>{facture.numero}</Text>
      </View>
      <View style={[styles.sectionColumn, styles.user]}>
        {societe && <Text>{societe}</Text>}
        <Text>{facture.last_name} {facture.first_name}</Text>
        <Text>{facture.adresse1}</Text>
        <Text>{facture.codepostal} {facture.location}</Text>
      </View>
      <View style={styles.section}>
        <Text style={[styles.headTable2, {width:'35%', fontSize:'10px'}]}>Description</Text>
        <Text style={[styles.headTable2, {width:'15%', fontSize:'10px'}]}>HT</Text>
        <Text style={[styles.headTable2, {width:'10%', fontSize:'10px'}]}>TVA</Text>
        <Text style={[styles.headTable2, {width:'15%', fontSize:'10px'}]}>Montant TVA</Text>
        <Text style={[styles.headTable2, {width:'18%', fontSize:'10px'}]}>Total TTC</Text>
      </View>
      <View style={styles.section}>
        <Text style={[styles.datasTable, {width:'35%'}]}>{facture.description}</Text>
        <Text style={[styles.datasTable, {width:'15%'}]}>{Number(facture.montant).toFixed(2)}</Text>
        <Text style={[styles.datasTable, {width:'10%'}]}>{Number(facture.tva).toFixed(2)}</Text>
        <Text style={[styles.datasTable, {width:'15%'}]}>{Number(facture.tva).toFixed(2)}</Text>
        <Text style={[styles.datasTable, {width:'18%',textAlign:'center'}]}>{Number(facture.montant).toFixed(2)}</Text>
      </View>
      <View style={styles.section}>
        <View style={[styles.sectionColumn, styles.totaux]}>
          <Text style={styles.headTable}>Total HT</Text>
          <Text style={styles.headTable}>TVA</Text>
          <Text style={styles.headTable}>Total TTC</Text>
        </View>
        <View style={[styles.sectionColumn, styles.totaux2]}>
          <Text style={styles.datasTable}>{Number(facture.montant).toFixed(2)}</Text>
          <Text style={styles.datasTable}>{Number(facture.tva).toFixed(2)}</Text>
          <Text style={[styles.datasTable, {textAlign:'center'}]}>{Number(facture.montant).toFixed(2)}</Text>
        </View>
      </View>
      <View style={[styles.sectionColumn, styles.copyright]}>
        <Text>TVA non applicable, article 293B du CGI</Text>
        <Text>Téléphone : 02 96 42 84 40 - Email : contact@la-matrice.org - Site : www.la-matrice.org</Text>
        <Text>Association Kreizenn Dafar - 7 Rue Poulain Corbion - 22000 Saint-Brieuc - W224003140 - 53936551000020 - 9499Z</Text>
      </View>
    </Page>
  </Document>
)


export default PdfPage;

PdfPage.propTypes = {
  facture: PropTypes.shape({
    id: PropTypes.number,
    datefacture: PropTypes.string,
    numero: PropTypes.string,
    last_name: PropTypes.string,
    first_name: PropTypes.string,
    adresse1: PropTypes.string,
    codepostal: PropTypes.string,
    location: PropTypes.string,
    description: PropTypes.string,
    montant: PropTypes.number,
    tva: PropTypes.number,
    datef: PropTypes.string,
  }).isRequired,
  societe: PropTypes.string,
};